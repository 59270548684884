import TemplateContents from './TemplateContents';

import {gsap, Quart} from "gsap";

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
    }

    setDom(){
        super.setDom();

        this.headerDOM = document.querySelector('header');
        this.grill = document.querySelector('#grill');
        this.menu = document.querySelector('#menu');
        this.mainNavDom = document.querySelector('#menu .main_nav');
        this.toContactBtn = document.querySelector('#menu .to_contact');
        this.cp = document.querySelector('#menu .cp');
    }

    initEvents(){
        super.initEvents();

        this.grill.addEventListener('click', this.clickGrillHandler.bind(this));
    }

    clickGrillHandler(event){
        if(this.menu.classList.contains('open')) this.close();
        else this.open();
    }

    open(){
        this.menu.classList.add('open');
        this.grill.classList.add('open');
        gsap.delayedCall(.1, ()=>{
            this.mainNavDom.classList.add('show');
            this.toContactBtn.classList.add('show');
            this.cp.classList.add('show');

        });
    }

    close(){
        this.mainNavDom.classList.remove('show');
        this.toContactBtn.classList.remove('show');
        this.cp.classList.remove('show');
        gsap.delayedCall(.35, ()=>{
            this.menu.classList.remove('open');
            this.grill.classList.remove('open');
        });
    }

    closeMenuExternal(){
        if(this.sw < this.pack.BP) this.close();
    }

    start(){
        gsap.delayedCall(.5, ()=>{
            if(this.sw >=this.pack.BP){
                this.mainNavDom.classList.add('show');
                this.toContactBtn.classList.add('show');
            }
        });
    }

    changeColor(bool){
        // if(bool) this.headerDOM.classList.add('white');
        // else this.headerDOM.classList.remove('white');
    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
    }
}