import TemplateContents from './TemplateContents';
import {gsap, Quint} from "gsap";

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();
        // this.pack.header.changeColor(false);
        this.myCall.kill();
        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();

        this.showerCnt = 0;
        this.interval = 1;
    }

    setDom(){
        super.setDom();

        this.heroDom = document.querySelector('main.page404 .hero');
        this.graffitiWraps = document.querySelectorAll('main.page404 .hero .graffiti .img');
        this.graffitiImgs = document.querySelectorAll('main.page404 .hero .graffiti .img img');
    }

    initEvents(){
        super.initEvents();
    }

    start(isLanding){
        // this.pack.header.changeColor(true);
        let delay;
        if(isLanding){
            delay = .01;
        }else{
            delay = .5;
        }

        gsap.delayedCall(delay, ()=>{
            this.heroDom.classList.add('show');
        });

        this.shower(this.showerCnt);
    }

    shower(id){
        let w = this.graffitiWraps[id];
        let g = this.graffitiImgs[id];

        w.style.zIndex = this.showerCnt;
        gsap.set(g, {scale:2.7, opacity:0, rotate:Math.random() * 30 - 15});
        gsap.to(g, .5, {scale:1, opacity:1, ease:Quint.easeIn});

        if(id === this.graffitiImgs.length - 1) id = 0;
        else id++;
        this.showerCnt++;
        this.myCall = gsap.delayedCall(this.interval, ()=>{
            this.shower(id);
        });

        if(this.interval > .3) this.interval -= .1;
    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
    }
}