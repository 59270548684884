import TemplateContents from './TemplateContents';

import {gsap, Quart, Quad} from "gsap";
import GUI from 'lil-gui';
import * as THREE from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import vertex from './shaders/vertex_mask1.glsl';
import fragment from './shaders/frag_mask1.glsl';

import vertexSim from './shaders/vertex_simulation.glsl';
import fragmentSim from './shaders/frag_simulation.glsl';

import vertexParticles from './shaders/vertex_particles.glsl';
import fragmentParticles from './shaders/frag_particles.glsl';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init(true);
        this.canvasContainer = document.querySelector('main.top .hero .mask_target');
        this.canvas = document.createElement("canvas");
        this.canvasContainer.appendChild(this.canvas);
        this.sw = this.canvasContainer.offsetWidth;
        this.sh = this.canvasContainer.offsetHeight;
        this.canvas.width = this.sw;
        this.canvas.height = this.sh;

        // this.initGUI();
        this.initWebGL();
        this.initFBO();
        this.initMesh();
        this.resetVideo();
        this.initEvents();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();

        this.canvasContainer = document.querySelector('main.top .hero .mask_target');
        this.canvasContainer.appendChild(this.canvas);
        this.sw = this.canvasContainer.offsetWidth;
        this.sh = this.canvasContainer.offsetHeight;
        this.canvas.width = this.sw;
        this.canvas.height = this.sh;
        this.resetVideo();
        this.resizeHandler();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    destructAfterContentsOut(){
        this.videoTexture.dispose();
        this.material.uniforms.uVideoTexture.value = null;
        this.videoDOM.src = '';
        this.render();

        this.canvas.parentNode.removeChild(this.canvas);
    }

    setVars(){
        super.setVars();

        this.time = 0;

        this.splashPositions = [
            new THREE.Vector3(.24, .21, 0),
            new THREE.Vector3(.19, .46, 0),
            new THREE.Vector3(.29, .31, 0),
            new THREE.Vector3(.39, .04, 0),
            new THREE.Vector3(.21, .14, 0),
            new THREE.Vector3(.34, .16, 0),
            new THREE.Vector3(.18, .19, 0),
            new THREE.Vector3(.07, .33, 0),
            new THREE.Vector3(.06, .18, 0),
            new THREE.Vector3(.13, .21, 0),
            new THREE.Vector3(.19, .01, 0),
            new THREE.Vector3(.23, -.21, 0),
            new THREE.Vector3(.25, -.14, 0),
            new THREE.Vector3(.12, -.08, 0),
            new THREE.Vector3(.08, .08, 0),
            new THREE.Vector3(.14, -.31, 0),
            new THREE.Vector3(.07, -.08, 0),
            new THREE.Vector3(.0, .12, 0),
            new THREE.Vector3(.02, -.16, 0),
            new THREE.Vector3(.09, -.08, 0),
            new THREE.Vector3(-.01, -.03, 0),
            new THREE.Vector3(-.09, .11, 0),
            new THREE.Vector3(-.09, -.09, 0),
            new THREE.Vector3(-.03, .13, 0),
            new THREE.Vector3(-.24, .13, 0),
            new THREE.Vector3(-.07, .06, 0),
            new THREE.Vector3(-.22, -.03, 0),
            new THREE.Vector3(-.29, .24, 0),
            new THREE.Vector3(-.24, -.03, 0),
            new THREE.Vector3(-.24, .29, 0),
            new THREE.Vector3(-.43, .21, 0),
            new THREE.Vector3(-.25, -.01, 0),
        ];
        this.currentSplashPosition = new THREE.Vector2(this.splashPositions[0].x, this.splashPositions[0].y);
        this.currentSplashCnt = 0;

        this.splashCurve = new THREE.CatmullRomCurve3(this.splashPositions);
        this.splashPoints = this.splashCurve.getPoints( 600 );

        this.pointer = new THREE.Vector2();
        this.pointer2 = new THREE.Vector2();
        this.easePointer = new THREE.Vector2(0, 0);
        this.easePointer2 = new THREE.Vector2(0, 0);
        this.easePointer3 = new THREE.Vector2(0, 0);

        this.currentWave = 2.5;
        this.isCosWave = false;
        this.cosWaveCnt = 0;

        this.canvasX = 0;
        this.canvasY = 0;
    }

    setDom(){
        super.setDom();

        this.videoPCDOM = document.querySelector('main.top .hero video.pc');
        this.videoSPDOM = document.querySelector('main.top .hero video.sp');
        this.heroH2ChunksWrap = document.querySelector('main.top .hero h2 .chunks_wrap');
        this.heroMaskTarget = document.querySelector('main.top .hero .mask_target');
    }

    initEvents(){
        super.initEvents();
    }

    start(isLanding){
        let delay;
        if(isLanding){
            delay = 1.1;
        }else{
            delay = 2.1;
        }

        gsap.delayedCall(delay, ()=>{
            this.videoDOM.play();
            if(this.pack.hasTouch) return;
            gsap.delayedCall(1.2, ()=>{
                gsap.to(this, 1, {currentWave:8, ease:Quad.easeInOut, onComplete:()=>{
                    this.isCosWave = true;
                }});
            });
        });
    }

    initGUI(){
        this.settings = {
            splashX:this.splashPositions[this.splashPositions.length-1].x,
            splashY:this.splashPositions[this.splashPositions.length-1].y,
            wave: 8,
        }

        const gui = this.gui = new GUI();
        gui.add(this.settings, 'wave', 2, 10, 0.01);
        // gui.add(this.settings, 'splashX', -1, 1, 0.01);
        // gui.add(this.settings, 'splashY', -1, 1, 0.01);
    }

    initWebGL() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            alpha : true,
            antialias : true
        });
        // this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setPixelRatio(1);
        this.renderer.setSize(this.sw, this.sh);
        this.renderer.autoClear = true;

        this.scene = new THREE.Scene();

        let frustumSize = 1;

        this.camera = new THREE.OrthographicCamera(frustumSize / -2, frustumSize / 2, frustumSize / 2, frustumSize / -2, -1000, 1000);
        this.camera.position.set(0,0,2);

        this.raycaster = new THREE.Raycaster();
    }

    getRenderTarget(){
        const renderTarget = new THREE.WebGLRenderTarget(this.sw, this.sh, {
        // const renderTarget = new THREE.WebGLRenderTarget(window.innerWidth, window.innerHeight, {
           minFilter: THREE.NearestFilter,
           magFilter: THREE.NearestFilter,
           format:  THREE.RGBAFormat,
            type: THREE.HalfFloatType,      //for iOS (FloatTypeだとiOSではオフスクリーンレンダリングできず)
        });

        return renderTarget;
    }

    initFBO(){
        // this.size = 1024;
        // this.size = 512;
        // this.size = 1;
        this.size = this.sw >= this.pack.BP ? 768 : 384;
        this.fbo = this.getRenderTarget();
        this.fbo1 = this.getRenderTarget();

        this.fboScene = new THREE.Scene();
        this.fboCamera = new THREE.OrthographicCamera(-1,1,1,-1,-1,1);
        this.fboCamera.position.set(0,0,0.5);
        this.fboCamera.lookAt(0,0,0)
        let geometry = new THREE.PlaneGeometry(2,2);

        this.data = new Float32Array(this.size * this.size * 4);
        let screenAspect = this.sw / this.sh;
        for( let i = 0; i < this.size; i++ ){
            for( let j = 0; j < this.size; j++ ){
                let index = (i + j * this.size) * 4;
                let theta = Math.random() * Math.PI * 2;
                // let r = 0.5 + 0.5 * Math.random();
                let r = 0.15 + 0.15 * Math.random();
                this.data[index + 0] = r * Math.cos(theta);
                // this.data[index + 1] = r * Math.sin(theta);
                this.data[index + 1] = (r * screenAspect) * Math.sin(theta);
                this.data[index + 2] = 1.;
                this.data[index + 3] = 1.;
            }
        }

        this.fboTexture = new THREE.DataTexture(this.data, this.size, this.size, THREE.RGBAFormat, THREE.FloatType);
        this.fboTexture.magFilter = THREE.NearestFilter;
        this.fboTexture.minFilter = THREE.NearestFilter;
        this.fboTexture.needsUpdate = true;

        this.fboMaterial = new THREE.ShaderMaterial(
            {
                uniforms:{
                    uViewport: {value: new THREE.Vector2(this.sw, this.sh)},
                    uPositions: {value: this.fboTexture},
                    uInfo: {value: null},
                    uMouse: {value: new THREE.Vector2(0,0)},
                    uSplashPosition: {value: new THREE.Vector2(this.splashPositions[0].x, this.splashPositions[0].y)},
                    uTime: {value:0}
                },
                vertexShader: vertexSim,
                fragmentShader: fragmentSim,
            }
        );
        // this.fboMaterial.needsUpdate = true;

        this.infoList = new Float32Array(this.size * this.size * 4);
        for( let i = 0; i < this.size; i++ ){
            for( let j = 0; j < this.size; j++ ){
                let index = (i + j * this.size) * 4;
                // let r = 0.5 + 0.5 * Math.random();
                this.infoList[index + 0] = 0.5 * Math.random();
                this.infoList[index + 1] = 0.5 * Math.random();
                this.infoList[index + 2] = 1.;
                this.infoList[index + 3] = 1.;
            }
        }

        this.infoTexture = new THREE.DataTexture(this.infoList, this.size, this.size, THREE.RGBAFormat, THREE.FloatType);
        this.infoTexture.magFilter = THREE.NearestFilter;
        this.infoTexture.minFilter = THREE.NearestFilter;
        this.infoTexture.needsUpdate = true;
        this.fboMaterial.uniforms.uInfo.value = this.infoTexture;

        this.fboMesh = new THREE.Mesh(geometry, this.fboMaterial);
        this.fboScene.add(this.fboMesh);
        // this.scene.add(this.fboMesh);
    }

    initMesh(){
        this.ball = new THREE.Mesh(
            new THREE.PlaneGeometry(10,10),
            new THREE.MeshBasicMaterial()
        );

        this.initParticles();
        this.initPlane();
    }

    initParticles(){
        this.count = this.size**2;
        let material = this.particleMaterial = new THREE.ShaderMaterial({
            side: THREE.DoubleSide,
            uniforms:{
                uPositions: {value:null},
                uTime: {value:0},
                uColor:{value:new THREE.Vector3(0,0,0)}
            },
            vertexShader: vertexParticles,
            fragmentShader: fragmentParticles,
            transparent: true,
        });

        let geometry = new THREE.BufferGeometry();
        let positions = new Float32Array(this.count * 3);
        let uv = new Float32Array(this.count * 2);
        for( let i = 0; i < this.size; i++ ){
            for( let j = 0; j < this.size; j++ ){
                let index = (i + j * this.size);
                positions[index * 3 + 0] = Math.random();
                positions[index * 3 + 1] = Math.random();
                positions[index * 3 + 2] = 0.;
                uv[index * 2 + 0] = i / this.size;
                uv[index * 2 + 1] = j / this.size;
            }
        }
        geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        geometry.setAttribute('uv', new THREE.BufferAttribute(uv, 2));

        this.particleMaterial.uniforms.uPositions.value = this.fboTexture;
        this.points = new THREE.Points(geometry, material);

        this.postScene = new THREE.Scene();
        this.postRenderTarget = this.getRenderTarget();
        this.postScene.add(this.points);
        // this.scene.add(this.points);
    }

    initPlane(){
        let textureAspect, scale, adjustX, adjustY;
        this.videoDOM;
        this.textureMaskBG = this.pack.textures.mv_mask_bg;

        if(window.innerWidth >= this.pack.BP) {
            this.videoDOM = this.videoPCDOM;
            textureAspect = 1440 / 900;
            scale = .99;
            adjustX = .019;
            adjustY = 0.062;
        }else {
            this.videoDOM = this.videoSPDOM;
            textureAspect = 1440 / 1680;
            // scale = .55;
            scale = 1;
            adjustX = 0;
            adjustY = 0;
        }

        this.geometry = new THREE.PlaneGeometry( 1, 1, 32 );
        this.material = new THREE.ShaderMaterial({
            side: THREE.DoubleSide,
            uniforms:{
                uPositions: {value:0},
                uViewport: {value: new THREE.Vector2(this.sw, this.sh)},
                uTextureSplash: {value: this.postRenderTarget.texture},
                uTextureMaskBG: {value: this.textureMaskBG},
                uTexturePosition: {value: new THREE.Vector2(0, 0)},
                uTextureSize: {value: new THREE.Vector2(564, 598)},
                uVideoTexture: {value: null},
                uTextureAspect: {value: textureAspect},
                uScale: {value: scale},
                uAdjust: {value: new THREE.Vector2(adjustX, adjustY)},
                uMouse: {value: new THREE.Vector2(0,0)},
                uTime: {value:0},
                uWave: {value:2.5}
            },
            vertexShader: vertex,
            fragmentShader: fragment,
            transparent: true
        });

        let plane = new THREE.Mesh( this.geometry, this.material );
        this.scene.add( plane );
    }

    resetVideo(){
        this.videoDOM;

        if(window.innerWidth >= this.pack.BP)　this.videoDOM = this.videoPCDOM;
        else　this.videoDOM = this.videoSPDOM;
        this.videoTexture = new THREE.VideoTexture(this.videoDOM);
        this.material.uniforms.uVideoTexture.value = this.videoTexture;
        this.render();
    }

    scrollHandler(){

    }

    render(){
        this.currentSplashCnt++;
        if(this.currentSplashCnt === this.splashPoints.length) this.currentSplashCnt = 0;
        let position = this.splashPoints[this.currentSplashCnt];
        this.currentSplashPosition = new THREE.Vector2(position.x, position.y);

        // this.fboMaterial.uniforms.uSplashPosition.value = new THREE.Vector2(this.settings.splashX, this.settings.splashY);      //for debug
        this.fboMaterial.uniforms.uSplashPosition.value = new THREE.Vector2(this.currentSplashPosition.x, this.currentSplashPosition.y);

        this.renderer.setRenderTarget(this.fbo);
        this.renderer.render(this.fboScene, this.fboCamera);

        this.renderer.setRenderTarget(this.postRenderTarget);
        this.renderer.render(this.postScene, this.camera);

        this.renderer.setRenderTarget(null);
        this.renderer.render(this.scene, this.camera);

        this.time += .05;
        this.fboMaterial.uniforms.uTime.value = this.time;
        this.fboMaterial.uniforms.uPositions.value = this.fbo.texture;
        this.particleMaterial.uniforms.uPositions.value = this.fbo1.texture;
        this.particleMaterial.uniforms.uTime.value = this.time;
        this.material.uniforms.uTime.value = this.time;

        //swap render targets;
        let temp = this.fbo;
        this.fbo = this.fbo1;
        this.fbo1 = temp;
    }

    enterframe(){

    }

    enterframeThinOut(){
        // this.controls.update();

        if(!this.pack.hasTouch){
            let x = this.pack.top.pointer.x - this.canvasX;
            let y = this.pack.top.pointer.y - this.canvasY;

            this.pointer.x = ( x / this.sw) * 2 - 1;
            this.pointer.y = -(y / this.sh) * 2 + 1;

            this.pointer2.x = x - this.swh;
            this.pointer2.y = y - this.shh;

            this.easePointer.x += (this.pointer.x - this.easePointer.x) * .5;
            this.easePointer.y += (this.pointer.y - this.easePointer.y) * .5;

            this.easePointer2.x += (-this.pointer2.x * .05 - this.easePointer2.x) * .08;
            this.easePointer2.y += (-this.pointer2.y * .05 - this.easePointer2.y) * .08;

            this.easePointer3.x += (-this.pointer2.x * .09 - this.easePointer3.x) * .14;
            this.easePointer3.y += (-this.pointer2.y * .09 - this.easePointer3.y) * .14;

            this.heroH2ChunksWrap.style.transform = `translate3d(${this.easePointer2.x}px, ${this.easePointer2.y}px, 0)`;
            this.heroMaskTarget.style.transform = `translate3d(${this.easePointer3.x}px, ${this.easePointer3.y}px, 0)`;

            this.raycaster.setFromCamera(this.easePointer, this.camera);
            let intersects = this.raycaster.intersectObject(this.ball);
            if(intersects.length > 0){
                let [x,y] = intersects[0].point;
                this.fboMaterial.uniforms.uMouse.value = new THREE.Vector2(x,y);
                this.material.uniforms.uMouse.value = new THREE.Vector2(x,y);
            }
        }

        // this.material.uniforms.uWave.value = this.settings.wave;        //for debug
        if(this.isCosWave){
            this.cosWaveCnt += .5;
            let rad = this.pack.d2r(this.cosWaveCnt);
            let cos = (Math.cos(rad) + 1) * .5;
            this.currentWave = 2 + cos * 6;
        }

        this.material.uniforms.uWave.value = this.currentWave;

        this.render();
    }

    setCanvasPosition(){
        if(this.sw >= this.pack.BP){
            this.canvasX = 0;
            this.canvasY = 0;
        }else{
            let rect = this.canvas.getBoundingClientRect();
            this.canvasX = rect.left;
            this.canvasY = rect.top;
        }
    }

    executeResize() {
        // super.executeResize();
        this.sw = window.innerWidth;
        this.sh = window.innerHeight;
        if(this.sw >= this.pack.BP){
            this.sw = this.canvasContainer.offsetWidth;
            this.sh = this.canvasContainer.offsetHeight;
        }else{
            let videoAspect = 1400 / 1680;
            let videoInverseAspect = 1 / videoAspect;
            this.sw = this.sw * (1 / 0.55);
            this.sh = this.sw * videoInverseAspect;
        }

        this.swh = this.sw / 2;
        this.shh = this.sh / 2;

        if(!this.canvas) return;

        this.canvas.width = this.sw;
        this.canvas.height = this.sh;

        if(!this.camera) return;

        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.sw, this.sh);

        this.postRenderTarget.setSize(this.sw, this.sh);

        this.material.uniforms.uViewport.value = new THREE.Vector2(this.sw, this.sh);
        this.fboMaterial.uniforms.uViewport.value = new THREE.Vector2(this.sw, this.sh);

        this.setCanvasPosition();
    }
}