import {gsap, Quart} from "gsap";
import TemplateContents from './TemplateContents';

import * as THREE from 'three';
import vertex from './shaders/vertex_transition.glsl';
import fragment from './shaders/frag_transition.glsl';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init(true);

        this.canvasContainer = document.querySelector('#transition .mask_target');
        this.canvas = document.createElement("canvas");
        this.canvasContainer.appendChild(this.canvas);
        this.sw = this.canvasContainer.offsetWidth;
        this.sh = this.canvasContainer.offsetHeight;
        this.canvas.width = this.sw;
        this.canvas.height = this.sh;

        this.initEvents();
        this.initWebGL();
        this.initMesh();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
        this.enabled = false;
    }

    setDom(){
        super.setDom();

        this.transitionDom = document.querySelector('#transition');

        this.transitionInPcVideo = document.querySelector('#transition .pc.transition_in');
        this.transitionOutPcVideo = document.querySelector('#transition .pc.transition_out');

        this.transitionInSpVideo = document.querySelector('#transition .sp.transition_in');
        this.transitionOutSpVideo = document.querySelector('#transition .sp.transition_out');
    }

    initEvents(){
        super.initEvents();
    }

    start(){

    }

    initWebGL() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            alpha : true,
            antialias : true
        });
        this.renderer.setPixelRatio(1);
        this.renderer.setSize(this.sw, this.sh);
        this.renderer.autoClear = false;

        this.scene = new THREE.Scene();

        let frustumSize = 1;

        this.camera = new THREE.OrthographicCamera(frustumSize / -2, frustumSize / 2, frustumSize / 2, frustumSize / -2, -1000, 1000);
        this.camera.position.set(0,0,2);
    }

    initMesh(){
        let textureAspect;
        if(this.sw >= this.pack.BP) {
            this.inVideoDOM = this.transitionInPcVideo;
            this.outVideoDOM = this.transitionOutPcVideo;
            textureAspect = 1440 / 900;
        }else {
            this.inVideoDOM = this.transitionInSpVideo;
            this.outVideoDOM = this.transitionOutSpVideo;
            textureAspect = 390 / 844;
        }

        let videoTexture = new THREE.VideoTexture(this.inVideoDOM);
        let geometry = new THREE.PlaneGeometry( 1, 1, 32 );
        let material = this.material = new THREE.ShaderMaterial({
            side: THREE.DoubleSide,
            uniforms:{
                uViewport: {value: new THREE.Vector2(this.sw, this.sh)},
                uVideoTexture: {value: videoTexture},
                uTextureAspect: {value: textureAspect},
            },
            vertexShader: vertex,
            fragmentShader: fragment,
            transparent: true
        });

        let plane = new THREE.Mesh( geometry, material );
        this.scene.add( plane );
    }

    transitIn(callback, scope){
        this.enabled = true;
        let videoTexture = new THREE.VideoTexture(this.inVideoDOM);
        this.material.uniforms.uVideoTexture.value = videoTexture;

        this.transitionDom.classList.add('show');
        this.resizeHandler();
        this.inVideoDOM.play();
        gsap.delayedCall(1, ()=>{
            if(scope) callback.call(scope);
            else callback();
        });
    }

    transitOut(){
        let videoTexture = new THREE.VideoTexture(this.outVideoDOM);
        this.outVideoDOM.play();
        gsap.delayedCall(.15, ()=>{
            this.material.uniforms.uVideoTexture.value = videoTexture;
        });

        gsap.delayedCall(1, ()=>{
            this.transitionDom.classList.remove('show');
            this.enabled = false;
        });
    }

    scrollHandler(){

    }

    render(){
        this.renderer.render(this.scene, this.camera);
    }

    enterframe(){

    }

    enterframeThinOut(){
        if(this.enabled) this.render();
    }

    executeResize() {
        super.executeResize();
        // this.sw = this.canvasContainer.offsetWidth;
        // this.sh = this.canvasContainer.offsetHeight;
        // this.swh = this.sw / 2;
        // this.shh = this.sh / 2;

        if(!this.canvas) return;

        this.canvas.width = this.sw;
        this.canvas.height = this.sh;

        if(!this.camera) return;

        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.sw, this.sh);
    }
}