import TemplateContents from './TemplateContents';
import {gsap} from "gsap";

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    destructAfterContentsOut(){
        trace('次の遷移先', this.pack.current);
        if(this.pack.current != 'news_details'){
            //sessionStorage に allをセット
            sessionStorage.setItem('referrer', 'all');
        }
    }

    setVars(){
        super.setVars();
        const categories = document.querySelectorAll('.categories ul li')
        categories.forEach((category) => {
            category.classList.remove('current');
            if (category.dataset.slug === sessionStorage.getItem('referrer')) {
                category.classList.add('current');
            };
        });

        this.term = sessionStorage.getItem('referrer')
    }

    async fetchAdjacentPosts() {
        const postId = document
          .querySelector('meta[name="post-id"]')
          .getAttribute('content')

        // termの値に応じてカテゴリフィルターを適用
        const response = await fetch(
          `https://new.rerise.co/wp-json/custom/v1/adjacent_posts?post_id=${postId}&term=${sessionStorage.getItem(
            'referrer'
          )}`
        )
        const data = await response.json()


        // 前後記事のリンクをDOMに設定
        const prevBt = document.querySelector('.prev_bt')
        if (data.prev && data.prev.length) {
            prevBt
                .querySelector('a')
                .setAttribute('href', data.prev)
        }
        else {
            prevBt
              .querySelector('a')
              .setAttribute('style', 'pointer-events: none;')
            prevBt.classList.add('disabled')
        }

        const nextBt = document.querySelector('.next_bt')
        if (data.next && data.next.length) {
            nextBt
                .querySelector('a')
                .setAttribute('href', data.next)
        }
        else {
            nextBt
                .querySelector('a')
                .setAttribute('style', 'pointer-events: none;')
            nextBt.classList.add('disabled')
        }
    }

    setDom(){
        super.setDom();
        this.section1Dom = document.querySelector('main.news_details .section1');
        this.section1H2Pathes = document.querySelectorAll('main.news_details .section1 h2 svg path');

        this.fetchAdjacentPosts();
    }

    initEvents(){
        super.initEvents();
    }

    setTransitionDelay(){
        let singleDelay = .08;
        let delayAll = singleDelay * (this.section1H2Pathes.length - 1);
        for( let i = 0, len = this.section1H2Pathes.length; i < len; i++ ){
            let item = this.section1H2Pathes[i];
            let delay = delayAll - (singleDelay * i);
            item.style.transitionDelay = delay + 's';
        }
    }

    start(isLanding){
        let delay;
        if(isLanding){
            delay = .01;
        }else{
            delay = .5;
        }

        this.setTransitionDelay();

        gsap.delayedCall(delay, ()=>{
            this.section1Dom.classList.add('show');
        });
    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
    }
}