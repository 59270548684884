import "@babel/polyfill";

import Config from "./Config";
import Common from './Common';

require('./libs/daijima/trace.js');
require('./libs/daijima/requestanimationframe.js');

import "../scss/common.scss";
import "../scss/top.scss";
import "../scss/about.scss";
import "../scss/service.scss";
import "../scss/news.scss";
import "../scss/news_details.scss";
import "../scss/contact.scss";
import "../scss/contact_thanks.scss";
import "../scss/privacy.scss";
import "../scss/page404.scss";

//CSS Modulesとして使用する場合
// import * as styles from "../scss/common.scss";

Config.getInstance();
new Common();

require('./barba-custom.js');