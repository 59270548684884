import {gsap, Quart} from "gsap";

import TemplateContents from './TemplateContents';
// import BarbaCustom from './BarbaCustom';

import Loading from './Loading';
import Transition from './Transition';
import Header from './Header';
import Footer from './Footer';

import Top from './Top';
import About from './About';
import Service from "./Service";
import News from "./News";
import NewsDetails from "./NewsDetails";
import Contact from './Contact';
import ContactThanks from "./ContactThanks";
import PrivacyPolicy from "./PrivacyPolicy";
import Page404 from "./Page404";

export default class extends TemplateContents{
    constructor(param){
        super(param);

        this.pack.common = this;
        // this.pack.barba = new BarbaCustom();

        this.bindloadCompletedHandler = this.loadCompletedHandler.bind(this);
        this.bindloadedBGWallHandler = this.loadedBGWallHandler.bind(this);
        this.pack.loading = new Loading();
        this.pack.loading.addEventListener('loadCompleted', this.bindloadCompletedHandler);
        this.pack.loading.addEventListener('loadedBGWall', this.bindloadedBGWallHandler);
    }

    init() {
        super.init();
    }

    addScrollTarget(target){
        this.scrollTarget = target;
    }

    removeScrollTarget(){
        this.scrollTarget = undefined;
    }

    addEnterframeTarget(target){
        this.enterframeTarget = target;
    }

    removeEnterframeTarget(){
        this.enterframeTarget = undefined;
    }

    setVars(){
        super.setVars();
        this.enterframeID;
        this.enterframeIgnoreCnt = 0;

        this.pastPageYOffset = 0;
        this.scrollYOffset = 0;
        this.pastScrollYOffset = 0;

        this.UPDATE_LOAD_COEFF = 0.5;
        this.FPS = 30;
        this.targetInterval = 1000 / this.FPS;
        this.prevTime = Date.now() - this.targetInterval;

        this.isPc = undefined;
    }

    setDom(){
        super.setDom();
        this.scrollWrapper = document.querySelector('.scroll-wrapper');
        // this.barbaWrapper = document.querySelector('.barba-wrapper');
        this.barbaContainer = document.querySelector('.barba-container');
    }

    initEvents(){
        super.initEvents();

        this.resizeEvent = navigator.userAgent.match(/(iPhone|iPod|iPad)/) ? 'orientationchange' : 'resize';

        // window.addEventListener("load", this.loadedHandler.bind(this));
        window.addEventListener("DOMContentLoaded", this.DOMContentloadedHandler.bind(this));

        window.addEventListener(this.resizeEvent, this.resizeHandler.bind(this), false);
        window.addEventListener('scroll', this.scrollHandler.bind(this),{passive: false});

        this.resizeHandler();
        this.enterframe();
    }

    DOMContentloadedHandler(event){
        this.pack.loading.start();

        document.body.style.opacity = 1;
    }

/*
    loadedHandler(event){

    }
*/

    loadedBGWallHandler(event){
        // trace('loadedBGWallHandler');
        let namespace = this.barbaContainer.dataset.namespace;
        if (namespace === 'top') {
            this.pack.top = new Top();
        }
    }

    loadCompletedHandler(event){
        this.pack.loading.removeEventListener('loadCompleted', this.bindloadCompletedHandler);
        this.initContainer();
    }

    initContainer() {
        window.scrollTo(0, 0);

        let namespace = this.barbaContainer.dataset.namespace;
        this.pack.current = namespace;

        this.pack.header = new Header();
        this.pack.footer = new Footer();
        this.pack.header.start();
        this.pack.footer.start();

        this.pack.transition = new Transition();

        if (namespace === 'top') {
            this.pack.top.start(true);
        }else if(namespace === 'about'){
            this.pack.about = new About();
            this.pack.about.start(true);
        }else if(namespace === 'service'){
            this.pack.service = new Service();
            this.pack.service.start(true);
        }else if(namespace === 'news'){
            this.pack.news = new News();
            this.pack.news.start(true);
        }else if(namespace === 'news_details'){
            this.pack.news_details = new NewsDetails();
            this.pack.news_details.start(true);
        }else if(namespace === 'contact'){
            this.pack.contact = new Contact();
            this.pack.contact.start(true);
        }else if(namespace === 'contact_thanks'){
            this.pack.contact_thanks = new ContactThanks();
            this.pack.contact_thanks.start(true);
        }else if(namespace === 'privacy'){
            this.pack.privacy = new PrivacyPolicy();
            this.pack.privacy.start(true);
        }else if(namespace === 'page404'){
            this.pack.page404 = new Page404();
            this.pack.page404.start(true);
        }

        if(namespace === 'top'){
            this.pack.footer.setFooter(true);
        }else{
            this.pack.footer.setFooter(false);
            document.documentElement.classList.remove('lock');
        }

        this.setCurrent();
    }

    setCurrent(){
        // this.pack.header.setCurrent();
    }

    start(){

    }

    startTransitionIn(callback, scope){
        // let patern = (this.pack.current === 'top') ? 2 : 1;
        // this.pack.transition.setThreeD();
        this.pack.transition.transitIn(callback, scope);

    }

    startTransitionOut(){
        this.pack.transition.transitOut();
    }

    resetScrollY(){
        this.pack.scrollY = 0;
    }

    scrollHandler(){
        this.pack.scrollY = window.scrollY;
        if (this.scrollTarget) this.scrollTarget.scrollHandler();
    }

    enterframe(){
        this.enterframeID = window.requestAnimationFrame(this.enterframe.bind(this));

        if(this.enterframeTarget && this.enterframeTarget.enterframe) this.enterframeTarget.enterframe();

        // this.smoothScroll();

        let currentTime = Date.now();
        let updated = false;
        while (currentTime - this.prevTime > this.targetInterval) {

            updated = true;
            this.prevTime += this.targetInterval;
            const now = Date.now();
            const updateTime = now - currentTime;
            if (updateTime > this.targetInterval * this.UPDATE_LOAD_COEFF) {
                // overloaded
                if (this.prevTime < now - this.targetInterval) {
                    // do not accumulate too much
                    this.prevTime = now - this.targetInterval;
                }
                break;
            }
        }

        if (updated) {
            if(this.enterframeTarget) this.enterframeTarget.enterframeThinOut();
            if(this.pack.loading) this.pack.loading.enterframeThinOut();
            if(this.pack.transition) this.pack.transition.enterframeThinOut();
        }

        if(this.enterframeIgnoreCnt % 90 === 0){
            //間引き処理(1 execution per 90 frames)
            this.resizeHandler();
        }

        //前回のスクロール位置（全ての処理が終わってから代入）
        this.pastPageYOffset = this.pack.pastScrollY = this.pack.scrollY;

        this.enterframeIgnoreCnt++;
    }

    enterframeThinOut(){

    }

    reload(){
        location.reload(false);
    }

    executeResize() {
        super.executeResize();
        if(this.isPc === undefined){
            if(this.sw >= this.pack.BP) this.isPc = true;
            else this.isPc = false;
        }else{
            if(this.sw >= this.pack.BP){
                if(!this.isPc) this.reload();
            }else{
                if(this.isPc) this.reload();
            }
        }
    }
}