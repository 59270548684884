import {gsap, Quart, Quint} from "gsap";

import TemplateContents from './TemplateContents';
import AboutCarousel from './AboutCarousel';
import TopMask3 from "./TopMask3";

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();
        this.initCarousel();

        if(this.pack.mask3) {
            this.mask3 = this.pack.mask3;
            this.mask3.reset();
        }else {
            this.mask3 = this.pack.mask3 = new TopMask3();
            this.mask3.setCanvasDOM();
        }

        this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        this.initCarousel();

        this.mask3.reset();

        this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();
        this.destructCarousel();
        this.pack.common.removeScrollTarget(this);
        this.pack.common.removeEnterframeTarget(this);
    }

    destructAfterContentsOut(){
        this.mask3.destructAfterContentsOut();
    }

    setVars(){
        super.setVars();

        this.enabled = false;
        this.enabledScroll = false;
        this.currentSection = 0;    //0->hero, 1->section1
        this.isInTransition = false;


        this.imgsHighestY;
        this.imgHeight;
        this.currentImgsDifY = 0;
        this.enabledRendering = false;
        this.mask3Started = false;
    }

    setDom(){
        super.setDom();

        this.heroDom = document.querySelector('main.about .hero');
        this.h1Dom = document.querySelector('main.about .hero h1');
        this.h1Pathes = document.querySelectorAll('main.about .hero h1 .tit svg path');
        this.h1Imgs = document.querySelectorAll('main.about .hero h1 .tit img');
        this.h1SubTit = document.querySelector('main.about .hero h1 .sub_tit');

        this.section1Dom = document.querySelector('main.about .section1');
        this.section1H2 = document.querySelector('main.about .section1 h2');
        this.section1Txt = document.querySelector('main.about .section1 .txt');
        this.section1CopyPathes = document.querySelectorAll('main.about .section1 .copy svg path');
        this.section1Imgs = document.querySelectorAll('main.about .section1 .copy img');
        this.bgDom = document.querySelector('main.about .bg');

        this.section2Dom = document.querySelector('main.about .section2');
        this.section2H2 = document.querySelector('main.about .section2 h2');
        this.section2TxtItems = document.querySelectorAll('main.about .section2 .txt .item');

        this.section3Dom = document.querySelector('main.about .section3');
        this.section3H2 = document.querySelector('main.about .section3 h2');
        this.section3Carousel = document.querySelector('main.about .section3 .chronology .carousel_wrap .carousel');
        this.section3CarouselItems = document.querySelectorAll('main.about .section3 .chronology .carousel_wrap .carousel .carousel_item');
        this.section3FigureChunk = document.querySelector('main.about .section3 .figure_chunk');

        this.section4Dom = document.querySelector('main.about .section4');
        this.section4H2 = document.querySelector('main.about .section4 h2');
        this.imagesWrapDom = document.querySelector('main.about .images');
        this.imagesPCDom = document.querySelectorAll('main.about .images .pc img');
        this.imagesSPDom = document.querySelectorAll('main.about .images .sp img');
    }

    initEvents(){
        super.initEvents();
    }

    initCarousel(){
        this.carousel = new AboutCarousel();
        this.carousel.setTarget(this.section3Dom);
        this.carousel.setMarginRightPC(true, this.pack.getPCVW(0));
        this.carousel.setMarginRightSP(true, this.pack.getSPVW(0));
        this.carousel.setAdjustMinXPC(true, this.pack.getPCVW(66));    　　  //カルーセルの絶対値のX軸を設定
        this.carousel.setAdjustMinXSP(true, this.pack.getSPVW(0));         //カルーセルの絶対値のX軸を設定
    }

    destructCarousel(){
        this.carousel.destruct();
    }

    setTransitionDelay(){
        this.setHeroTransitionDelay();
        this.setSection1TransitionDelay();
        this.setSection2TransitionDelay();
    }

    setHeroTransitionDelay(){
        let singleDelay = .08;
        let delayAll = singleDelay * (this.h1Pathes.length - 1);
        for( let i = 0, len = this.h1Pathes.length; i < len; i++ ){
            let item = this.h1Pathes[i];
            let delay = delayAll - (singleDelay * i);
            item.style.transitionDelay = delay + 's';
        }
        this.h1SubTit.style.transitionDelay = '.7s';
    }

    setSection1TransitionDelay(){
        let singleDelay = .08;
        let delayAll = singleDelay * (this.section1CopyPathes.length - 1);
        for( let i = 0, len = this.section1CopyPathes.length; i < len; i++ ){
            let item = this.section1CopyPathes[i];
            let delay = delayAll - (singleDelay * i);
            item.style.transitionDelay = delay + 's';
        }
    }

    setSection2TransitionDelay(){
        for( let i = 0, len = this.section2TxtItems.length; i < len; i++ ){
            let item = this.section2TxtItems[i];
            item.style.transitionDelay = .6 + i * .2 + 's';
        }
    }


    checkHash(){
        gsap.delayedCall(1, ()=>{
            if(location.hash === '#slogan') {
                this.scrollToSection1();
            }else if(location.hash === '#top_message') {
                let targetY = this.section2Dom.getBoundingClientRect().top - 300;
                this.scrollToSection1(targetY);
                gsap.delayedCall(.6, ()=>{
                    this.section2H2.classList.add('show');
                    this.section2Dom.classList.add('show');
                });
            }
        });
    }

    start(isLanding){

        this.checkHash();

        let delay, delay2;
        if(isLanding){
            delay = .01;
            delay2 = 1.01;
        }else{
            delay = .5;
            delay2 = 1.5;
        }

        this.enabled = true;
        this.enabledScroll = true;

        this.setTransitionDelay();

        gsap.delayedCall(delay, ()=>{
            this.heroDom.classList.add('show');
        });

        gsap.delayedCall(delay2, ()=>{
            this.h1SubTit.style.transitionDelay = '0s';
        });
    }

    transitSection(current, targetY){
        this.isInTransition = true;
        this.currentSection = current;
        document.documentElement.classList.add('lock');
        this.pack.anchorScroll(targetY, 1, Quart.easeInOut, ()=>{
            document.documentElement.classList.remove('lock');
            this.isInTransition = false;
            if(this.currentSection === 1 && !this.section1Dom.classList.contains('show')) {
                this.section1Dom.classList.add('show');
                this.section1H2.classList.add('show');
                this.section1Txt.classList.add('show');
            }
        });
    }

    scrollBG(){
        let scrollY = this.pack.scrollY;
        let moveArea = this.section1Dom.clientHeight + this.section2Dom.clientHeight + this.section3Dom.clientHeight - this.keepDesignAspectSh;
        let section1Scroll = scrollY - this.sh;
        // let moveHeight = this.shh / 2;
        let moveHeight = this.keepDesignAspectSh * .4;
        let current = section1Scroll / moveArea;
        if(current > 1) current = 1;
        let section1ScrollY = moveHeight - moveHeight * current;
        this.bgDom.style.transform = `translate3d(0, ${section1ScrollY}px, 0)`;
    }

    scrollToSection1(targetY){
        let y = targetY || this.sh;
        this.transitSection(1, y);
        this.heroDom.classList.add('scale_down');
        this.h1SubTit.classList.add('fade_out');
        gsap.delayedCall(.6, ()=>{
            this.h1SubTit.classList.remove('fade_out');
            this.h1SubTit.classList.add('scale_down');
        });
    }

    scrollHandler(){
        let scrollY = this.pack.scrollY;
        this.scrollBG();
        if(!this.enabled) return;
        if(!this.enabledScroll) return;
        if(this.isInTransition) return;

        if(this.currentSection === 0){
            if( scrollY > 0) this.scrollToSection1();
        }else if(this.currentSection === 1) {
            if (scrollY < this.sh) {
                //back to hero
                this.transitSection(0, 0);
                this.heroDom.classList.remove('scale_down');
                this.h1SubTit.classList.add('fade_out');
                gsap.delayedCall(.6, ()=>{
                    this.h1SubTit.classList.remove('scale_down');
                    this.h1SubTit.classList.remove('fade_out');
                });
            }
        }

        let section3Top = this.section3Dom.getBoundingClientRect().top;
        let section4Top = this.section4Dom.getBoundingClientRect().top;

        if(!this.mask3Started){
            let section2Top = this.section2Dom.getBoundingClientRect().top;

            if(!this.section2H2.classList.contains('show')){
                if(section2Top <= this.shh){
                    this.section2H2.classList.add('show');
                    this.section2Dom.classList.add('show');
                }
            }

            if(section2Top <= this.shhh){
                gsap.delayedCall(1.2, ()=>{
                    this.enabledRendering = true;
                    this.mask3.start();
                });
                this.mask3Started = true;
            }
        }

        if(!this.section3H2.classList.contains('show')){
            if(section3Top <= this.shh){
                this.section3H2.classList.add('show');
            }
        }

        if(!this.section3FigureChunk.classList.contains('show')){
            if(this.section3FigureChunk.getBoundingClientRect().top < this.shh){
                !this.section3FigureChunk.classList.add('show');
            }
        }

        if(!this.section4H2.classList.contains('show')){
            if(section4Top <= this.shh){
                this.section4H2.classList.add('show');
                this.section4Dom.classList.add('show');
            }
        }


        this.currentImgsDifY = 0;
        let imagesWrapY = this.imagesWrapDom.getBoundingClientRect().top;
        if(imagesWrapY < this.imgsHighestY) this.currentImgsDifY = this.imgsHighestY - imagesWrapY;
    }

    enterframe(){

    }

    enterframeThinOut(){
        if(this.currentImgsDifY > 0){
            for( let i = 0, len = this.imagesDom.length; i < len; i++ ){
                let img = this.imagesDom[i];
                let targetY = (i + 1) * this.imgHeight;
                let addScale = (targetY - this.currentImgsDifY) / this.imgHeight * .1;
                if(addScale < 0) addScale = 0;
                let scale = .9 + addScale;
                let y = (.1 - addScale) * -50;
                if(scale > 1) {
                    y = 0;
                    scale = 1;
                }

                img.style.transform = `scale(${scale}) translate3d(0, ${y}rem, 0)`;
            }
        }

        if(this.enabledRendering) this.mask3.enterframeThinOut();

    }

    executeResize() {
        super.executeResize();
        this.shhh = this.shh * .5;
        this.keepDesignAspectSh = (this.sw >= this.pack.BP) ? this.sw * .625 : this.sw * 2.164;

        this.imagesDom = (this.sw >= this.pack.BP) ? this.imagesPCDom : this.imagesSPDom;
        this.imgsHighestY = (this.sw >= this.pack.BP) ? this.sw / 1440 * 171 : this.sw / 390 * 146;
        this.imgHeight = this.imagesDom[0].clientHeight;
    }
}