import TemplateContents from './TemplateContents';

import {gsap, Power1, Power4, Expo, Quint, Quart} from "gsap";
import GUI from 'lil-gui';
import * as THREE from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import vertex from './shaders/vertex_bg.glsl';
import fragment from './shaders/frag_bg.glsl';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init(true);

        // this.devicePixelRatio = window.devicePixelRatio || 1;
        // this.devicePixelRatio = Math.min(2, this.devicePixelRatio);

        this.canvasContainer = document.querySelector('main.top .canvas_container');
        this.canvas = document.createElement("canvas");
        this.canvasContainer.appendChild(this.canvas);
        this.sw = this.canvasContainer.offsetWidth;
        this.sh = this.canvasContainer.offsetHeight;
        this.canvas.width = this.sw;
        this.canvas.height = this.sh;

        this.initEvents();
        // this.initGUI();
        this.initWebGL();
        this.initMesh();
        this.initParticles();
        this.render();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();

        this.canvasContainer = document.querySelector('main.top .canvas_container');
        this.canvasContainer.appendChild(this.canvas);
        this.sw = this.canvasContainer.offsetWidth;
        this.sh = this.canvasContainer.offsetHeight;
        this.canvas.width = this.sw;
        this.canvas.height = this.sh;
        this.resizeHandler();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    destructAfterContentsOut(){
        this.material.uniforms.uX.value = this.bgPositions[0].x;
        this.material.uniforms.uY.value = this.bgPositions[0].y;
        this.material.uniforms.uScaleMountain.value = this.scale2Start;
        this.material.uniforms.uY2.value = (this.sw / this.sh >= 1 || this.sw < this.pack.BP) ? this.bgY2Start : 0;
        this.material.uniforms.uOpacityStar.value = 0;
        this.material.uniforms.uRadius.value = 0;
        this.material.uniforms.uScaleLattice.value = this.latticeScaleStart;
        this.material.uniforms.uProgressFooter.value = 0;
        this.material.uniforms.uOpacityLattice.value = 0;
        this.render();

        this.canvas.parentNode.removeChild(this.canvas);
    }

    setVars(){
        super.setVars();

        this.scale = 0.46;

        this.bgPositions = [
            {x: -.29, y: .5},
            {x:.50, y:-0.13},
            {x:-.35, y:-0.58}
        ];

        this.transitionDuration = 1.0;
        this.transitionEase = Quint.easeInOut;

        this.maskRadius = 0;
        this.maskExpanded = false;

        this.bgX = this.bgPositions[0].x;
        this.bgY = this.bgPositions[0].y;
        this.bgEaseX = this.bgX;
        this.bgEaseY = this.bgY;
        this.bgTransitionX = this.bgX;
        this.bgTransitionY = this.bgY;

        this.scale2Start = 1;
        this.scale2End = .61;
        this.scale2Start2 = this.scale2End;
        // this.scale2End2 = .30;
        this.scale2End2 = .05;

        this.scale2 = this.scale2Start;
        this.bgY2Start = (window.innerWidth >= this.pack.BP) ? -.1 : 0;
        this.bgY2End = 0.04;
        this.bgY2Start2 = this.bgY2End;
        this.bgY2End2 = -0.45;
        // this.bgY2End2 = 0.12;
        this.bgX2 = 0;
        this.bgY2 = this.bgY2Start;
        this.scale2Ease = this.scale2;
        this.bgEaseY2 = this.bgY2;
        this.opacityStar = 0;
        this.opacityStarEase = this.opacityStar;
        this.opacityLattice = 0;

        this.mountainProgress = 0;

        this.latticeScaleStart = 1;
        this.latticeScaleEnd = 0.4;
        this.latticeScale = this.latticeScaleStart;
        this.latticeScaleEase = this.latticeScale;

        this.pointer = new THREE.Vector2();
        this.easePointer = new THREE.Vector2(0, 0);
        this.bgFooterProgress = 0;
        this.easeBgFooterProgress = 0;

    }

    setDom(){
        super.setDom();

        this.hero = document.querySelector('main.top .hero');
        this.section1 = document.querySelector('main.top .section1');
        this.section2 = document.querySelector('main.top .section2');
    }

    initEvents(){
        super.initEvents();
    }

    set(){
        this.textureMountain = this.pack.textures.bg_mountain;
        this.textureLattice = this.pack.textures.bg_lattice;
        this.textureFooter = this.pack.textures.bg_footer;
        this.textureStar = this.pack.textures.bg_star;
        let particle = this.pack.textures.bg_particle;

        this.material.uniforms.uTextureMountain.value = this.textureMountain;
        this.material.uniforms.uTextureLattice.value = this.textureLattice;
        this.material.uniforms.uTextureFooter.value = this.textureFooter;
        this.material.uniforms.uTextureStar.value = this.textureStar;
        this.pMesh.material.map = particle;
        this.pMesh.material.needsUpdate = true;
    }

    start(){

    }

    initGUI(){
        this.settings = {
            scale: this.scale,
            x: this.bgPositions[0].x,
            y: this.bgPositions[0].y,
            scale2: this.scale2,
            x2: this.bgX2,
            y2: this.bgY2,
            scaleLattice: 1,
        };

        const gui = this.gui = new GUI();
        gui.add(this.settings, 'scale', 0.001, 2.0, 0.01);
        gui.add(this.settings, 'x', -1.0, 1.0, 0.01);
        gui.add(this.settings, 'y', -1.0, 1.0, 0.01);
        gui.add(this.settings, 'scale2', 0.001, 2.0, 0.01);
        gui.add(this.settings, 'x2', -1.0, 1.0, 0.01);
        gui.add(this.settings, 'y2', -1.0, 1.0, 0.01);
        gui.add(this.settings, 'scaleLattice', 0.5, 1.5, 0.01);
    }

    initWebGL() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            alpha : true,
            antialias : true
        });

        // this.renderer.setPixelRatio(window.devicePixelRatio);
        if(this.sw >= this.pack.BP && window.devicePixelRatio > 1) this.renderer.setPixelRatio(2);
        else this.renderer.setPixelRatio(1);

        this.renderer.setSize(this.sw, this.sh);
        // this.renderer.autoClear = false;

        this.scene = new THREE.Scene();
        this.scene2 = new THREE.Scene();

        this.renderTarget = new THREE.WebGLRenderTarget(this.sw, this.sh);

        let frustumSize = 1;
        this.camera = new THREE.OrthographicCamera(frustumSize / -2, frustumSize / 2, frustumSize / 2, frustumSize / -2, -1000, 1000);
        this.camera.position.set(0,0,2);

        // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    }

    initMesh(){
        this.textureWall = this.pack.textures.bg_wall;
        
        let geometry = new THREE.PlaneGeometry( 1, 1, 32 );

        let aspectLattice = (this.sw >= this.pack.BP) ? 2880 / 1800 : 780 / 1688;
        let aspectFooter = (this.sw >= this.pack.BP) ? 2480 / 1800 : 780 / 1688;

        let material = this.material = new THREE.ShaderMaterial({
            side: THREE.DoubleSide,
            uniforms:{
                // uResolution:{type:'v4', value:new THREE.Vector4()},
                uViewport: {value: new THREE.Vector2(this.sw, this.sh)},
                uTextureWall: {value: this.textureWall},
                uTextureMountain: {value: null},
                uTextureLattice: {value: null},
                uTextureFooter: {value: null},
                uTextureStar: {value: null},
                uAspectWall:{value: 6380 / 5906},
                uAspectMountain:{value: 6720 / 7567},
                uAspectLattice:{value: aspectLattice},
                uAspectFooter:{value: aspectFooter},
                uProgressFooter:{value: 0},
                uScaleWall: {value: this.scale},
                uX: {value: this.bgPositions[0].x},
                uY: {value: this.bgPositions[0].y},
                uScaleMountain: {value: this.scale2},
                uX2: {value: this.bgX2},
                uY2: {value: (this.sw / this.sh >= 1 || this.sw < this.pack.BP) ? this.bgY2 : 0},
                uRadius: {value: this.maskRadius},
                uTextureHoleMask: {value: this.renderTarget.texture},
                uScaleLattice: {value: 1},
                uOpacityStar: {value:this.opacityStar},
                uOpacityLattice: {value:this.opacityLattice}
            },

            vertexShader: vertex,
            fragmentShader: fragment,
            transparent: true
        });

        let plane = new THREE.Mesh( geometry, material );
        this.scene.add( plane );
        this.enabled = true;
    }

    range(a, b){
        let r = Math.random();
        return a * r + b * (1 - r);
    }

    initParticles() {
        // let particle = this.pack.textures.bg_particle;

        let pMesh = this.pMesh = new THREE.Mesh(
            new THREE.PlaneGeometry(.7, .7),
            new THREE.MeshBasicMaterial(
                {
                    // map: particle,
                    transparent: true,
                    blending: THREE.AdditiveBlending,
                    depthTest: false,
                    depthWrite: false,
                    // opacity:.3,
                }
            )
        );

        this.particles = [];
        for (let i = 0, len = 15; i < len; i++) {
            let p = pMesh.clone();
            let theta = this.range(.1, 2. * Math.PI);
            let r = this.range(.1, .2);

            p.position.x = r * Math.sin(theta);
            p.position.y = r * Math.cos(theta);
            p.userData.life = this.range(-2 * Math.PI,2 * Math.PI);

            this.particles.push(p);
            this.scene2.add(p);
        }
    }

    updateParticles(){

        this.particles.forEach (p=>{
            p.userData.life += .05;
            // p.scale.setScalar(1.0 + Math.sin( 0.5 * p.userData.life));
            // p.scale.setScalar(-1.0 + Math.sin( 0.5 * p.userData.life));
            p.scale.setScalar(this.maskRadius * .1 + Math.sin( 0.5 * p.userData.life));

            if(p.userData.life > 2 * Math.PI){
                p.userData.life = -2 * Math.PI;
            }
        });
    }

    backToHero(){
        gsap.to(this,this.transitionDuration, {bgTransitionX:this.bgPositions[0].x, bgTransitionY:this.bgPositions[0].y, ease:this.transitionEase, onUpdate:this.updateTransition.bind(this)});
    }

    startSection1(){
        gsap.to(this,this.transitionDuration,{bgTransitionX:this.bgPositions[1].x, bgTransitionY:this.bgPositions[1].y, ease:this.transitionEase, onUpdate:this.updateTransition.bind(this)});
    }

    backToSection1(){
        gsap.to(this,this.transitionDuration, {bgTransitionX:this.bgPositions[1].x, bgTransitionY:this.bgPositions[1].y, ease:this.transitionEase, onUpdate:this.updateTransition.bind(this)});
    }

    startSection2(){
        gsap.to(this,this.transitionDuration, {bgTransitionX:this.bgPositions[2].x, bgTransitionY:this.bgPositions[2].y, ease:this.transitionEase, onUpdate:this.updateTransition.bind(this)});
    }

    scrollHandler(){
        let scrollY = this.pack.scrollY;

        let per = (scrollY - this.pack.top.sectionHero12ScrollableSingleHeight * 2) / this.pack.top.sectionHero12ScrollableSingleHeight * 3;
        if(per > 1) per = 1;

        if(per >= 1) {
            if(!this.section2.classList.contains('hide')) this.section2.classList.add('hide');
        }else {
            if(this.section2.classList.contains('hide')) this.section2.classList.remove('hide');
        }

        //wall hole
        let borderY = this.pack.top.sectionHero12ScrollableSingleHeight * 2 + this.shh;
        if(scrollY >= borderY){
            let currentY = scrollY - borderY;
            let per = currentY / this.sh;
            this.maskRadius = per * 6;
            if(this.maskRadius >= 6) this.maskRadius = 6;
            this.opacityStar = 1;
        }else{
            this.maskRadius = 0;
            this.opacityStar = 0;
        }

        this.mountainProgress = 0;
        let mountainStartY = this.sh * 3 + this.shh;
        if(scrollY > mountainStartY){
            this.mountainProgress = (scrollY - mountainStartY) / (this.sh + this.shhh);
            // if(this.mountainProgress > 1) this.mountainProgress = this.mountainProgress;
        }

        if(this.mountainProgress <= 1){
            this.scale2 = this.pack.lerp(this.scale2Start, this.scale2End, this.mountainProgress);
            this.bgY2 = this.pack.lerp(this.bgY2Start, this.bgY2End, this.mountainProgress);
            this.latticeScale = this.pack.lerp(this.latticeScaleStart, this.latticeScaleEnd, this.mountainProgress);
        }else{
            let mountainProgress = this.mountainProgress * 1.3;
            let per = mountainProgress - 1;
            if(per > 1) per = 1;
            this.scale2 = this.pack.lerp(this.scale2Start2, this.scale2End2, per);
            this.bgY2 = this.pack.lerp(this.bgY2Start2, this.bgY2End2, per);
            this.latticeScale = this.pack.lerp(this.latticeScaleStart, this.latticeScaleEnd, this.mountainProgress);
        }

        //グラデーション背景
        let section4BottomY = this.pack.top.sectionHero12ScrollableSingleHeight * 4 + this.shh;
        this.bgFooterProgress = 0;
        if(scrollY > section4BottomY){
            let footerHeight = document.documentElement.scrollHeight - this.sh - section4BottomY;
            this.bgFooterProgress = (scrollY - section4BottomY) / footerHeight;
            this.opacityLattice = this.bgFooterProgress * 1.5;      //1.5倍のスピードでopacityを制御する
            if(this.bgFooterProgress > 1) this.bgFooterProgress = 1;
            if(this.opacityLattice > 1) this.opacityLattice = 1;
        }

        this.material.uniforms.uOpacityLattice.value = 1 - this.opacityLattice;
    }

    updateTransition(){
        let posX = this.bgTransitionX;
        let posY = this.bgTransitionY;
        let inputX = posX + this.easePointer.x;
        let inputY = posY + this.easePointer.y;
        this.material.uniforms.uX.value = inputX;
        this.material.uniforms.uY.value = inputY;
    }

    render(){
        // this.material.uniforms.uScaleWall.value = this.settings.scale;
        // this.material.uniforms.uX.value = this.settings.x;
        // this.material.uniforms.uY.value = this.settings.y;

        if(!this.pack.hasTouch && this.pack.top){
            let x = this.pack.top.pointer.x;
            let y = this.pack.top.pointer.y;

            this.pointer.x = ( x / this.sw) * 2 - 1;
            this.pointer.y = -(y / this.sh) * 2 + 1;

            this.easePointer.x += (this.pointer.x * .02 - this.easePointer.x) * .04;
            this.easePointer.y += (this.pointer.y * .02 - this.easePointer.y) * .04;

            if(!this.pack.top.isInTransition){
                let posX, posY;
                if(this.pack.top.currentSection === 0){
                    posX = this.bgPositions[0].x;
                    posY = this.bgPositions[0].y;
                }else if(this.pack.top.currentSection === 1) {
                    posX = this.bgPositions[1].x;
                    posY = this.bgPositions[1].y;
                }else{
                    posX = this.bgPositions[2].x;
                    posY = this.bgPositions[2].y;
                }

                this.bgEaseX = posX + this.easePointer.x;
                this.bgEaseY = posY + this.easePointer.y;
                this.material.uniforms.uX.value = this.bgEaseX;
                this.material.uniforms.uY.value = this.bgEaseY;
            }
        }


        let ease = 1 / 15;
        let ease2 = 1 / 3;

        this.scale2Ease += (this.scale2 - this.scale2Ease) * ease;
        this.bgEaseY2 += (this.bgY2 - this.bgEaseY2) * ease;
        this.opacityStarEase += (this.opacityStar - this.opacityStarEase) * ease2;

        this.material.uniforms.uScaleMountain.value = this.scale2Ease;
        this.material.uniforms.uY2.value = (this.sw / this.sh >= 1 || this.sw < this.pack.BP) ? this.bgEaseY2 : 0;
        this.material.uniforms.uOpacityStar.value = this.opacityStarEase;

        // this.material.uniforms.uScaleMountain.value = this.settings.scale2; //for debug
        // this.material.uniforms.uY2.value = this.settings.y2;       //for debug

        this.material.uniforms.uRadius.value = this.maskRadius;

        this.latticeScaleEase += (this.latticeScale - this.latticeScaleEase) * ease;

        // this.material.uniforms.uScaleLattice.value = this.settings.scaleLattice;        //for debug
        this.material.uniforms.uScaleLattice.value = this.latticeScaleEase;

        this.easeBgFooterProgress += (this.bgFooterProgress - this.easeBgFooterProgress ) * .1;
        this.material.uniforms.uProgressFooter.value = this.easeBgFooterProgress;

        this.renderer.setRenderTarget(this.renderTarget);
        this.renderer.render(this.scene2, this.camera);
        this.material.uniforms.uTextureHoleMask.value = this.renderTarget.texture;
        this.renderer.setRenderTarget(null);
        this.renderer.render(this.scene, this.camera);
    }

    enterframe(){

    }

    enterframeThinOut(){
        this.updateParticles();
        // this.controls.update();
        this.render();
    }

    executeResize() {
        // super.executeResize();
        this.sw = this.canvasContainer.offsetWidth;
        this.sh = this.canvasContainer.offsetHeight;
        this.swh = this.sw / 2;
        this.shh = this.sh / 2;
        this.shhh = this.shh >> 2;

        if(!this.canvas) return;

        this.canvas.width = this.sw;
        this.canvas.height = this.sh;

        if(!this.camera) return;

        // this.cameraZ = this.defCameraZ;
        // this.camera.position.z = this.cameraZ;

        // this.camera.aspect = this.sw / this.sh;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.sw, this.sh);
        this.material.uniforms.uViewport.value = new THREE.Vector2(this.sw, this.sh);
    }
}