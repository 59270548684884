import TemplateContents from './TemplateContents';
import {gsap, Quart} from "gsap";

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    destructAfterContentsOut(){

    }

    setVars(){
        super.setVars();

        this.enabled = false;
        this.enabledScroll = false;
        this.currentSection = 0;    //0->hero, 1->section1
        this.isInTransition = false;
    }

    setDom(){
        super.setDom();

        this.heroDom = document.querySelector('main.service .hero');
        this.h1Dom = document.querySelector('main.service .hero h1');
        this.h1Pathes = document.querySelectorAll('main.service .hero h1 .tit svg path');
        this.h1Imgs = document.querySelectorAll('main.service .hero h1 .tit img');
        this.h1SubTit = document.querySelector('main.service .hero h1 .sub_tit');

        this.section1Dom = document.querySelector('main.service .section1');
        this.section1H2 = document.querySelector('main.service .section1 h2');
        this.section1CopyChunks = document.querySelectorAll('main.service .section1 .list li .bigger .chunk');
        this.section1SmallerTits = document.querySelectorAll('main.service .section1 .list li .smaller .tit');
        this.section1SmallerTxts = document.querySelectorAll('main.service .section1 .list li .smaller .txt');

        this.bg1Dom = document.querySelector('main.service .bg .bg1');
        this.bg3Dom = document.querySelector('main.service .bg .bg3');

        this.section2Dom = document.querySelector('main.service .section2');
        this.section2H2 = document.querySelector('main.service .section2 h2');
        this.section2List = document.querySelector('main.service .section2 .list');
        this.section2Circles = document.querySelectorAll('main.service .section2 .list li');

        this.section3Dom = document.querySelector('main.service .section3');
        this.section3H2 = document.querySelector('main.service .section3 h2');
    }

    initEvents(){
        super.initEvents();
    }

    setTransitionDelay(){
        this.setHeroTransitionDelay();
        this.setSection1TransitionDelay();
        this.setSection2TransitionDelay();
    }

    setHeroTransitionDelay(){
        let singleDelay = .08;
        let delayAll = singleDelay * (this.h1Pathes.length - 1);
        for( let i = 0, len = this.h1Pathes.length; i < len; i++ ){
            let item = this.h1Pathes[i];
            let delay = delayAll - (singleDelay * i);
            item.style.transitionDelay = delay + 's';
        }

/*
        for( let i = 0, len = this.h1Imgs.length; i < len; i++ ){
            let item = this.h1Imgs[i];
            let delay = .6 + singleDelay * i;
            item.style.transitionDelay = delay + 's';
        }
*/

        this.h1SubTit.style.transitionDelay = '.7s';
    }

    setSection1TransitionDelay(){
        for( let i = 0, len = this.section1CopyChunks.length; i < len; i++ ){
            let chunk = this.section1CopyChunks[i];
            let items = chunk.querySelectorAll('.item')
            for( let j = 0, len2 = items.length; j < len2; j++ ){
                let item = items[j];
                item.style.transitionDelay = i * .3 + j * .03 + 's';
            }
        }

        for( let i = 0, len = this.section1SmallerTits.length; i < len; i++ ){
            let tit = this.section1SmallerTits[i];
            tit.style.transitionDelay = i * .3 + .3 + 's';
        }

        for( let i = 0, len = this.section1SmallerTxts.length; i < len; i++ ){
            let tit = this.section1SmallerTxts[i];
            tit.style.transitionDelay = i * .3 + .4 + 's';
        }
    }

    setSection2TransitionDelay(){
        for( let i = 0, len = this.section2Circles.length; i < len; i++ ){
            let circle = this.section2Circles[i];
            circle.style.transitionDelay = .75 + i * .13 + 's';
        }
    }

    start(isLanding){
        let delay, delay2;
        if(isLanding){
            delay = .01;
            delay2 = 1.01;
        }else{
            delay = .5;
            delay2 = 1.5;
        }

        this.enabled = true;
        this.enabledScroll = true;

        this.setTransitionDelay();

        gsap.delayedCall(delay, ()=>{
            this.heroDom.classList.add('show');
        });

        gsap.delayedCall(delay2, ()=>{
            this.h1SubTit.style.transitionDelay = '0s';
        });
    }

    transitSection(current, targetY){
        this.isInTransition = true;
        this.currentSection = current;
        document.documentElement.classList.add('lock');
        this.pack.anchorScroll(targetY, 1, Quart.easeInOut, ()=>{
            document.documentElement.classList.remove('lock');
            this.isInTransition = false;
            if(this.currentSection === 1 && !this.section1Dom.classList.contains('show')) {
                this.section1Dom.classList.add('show');
                this.section1H2.classList.add('show');
            }
        });
    }

    scrollBG(){
        let scrollY = this.pack.scrollY;
        let moveArea = this.section1Dom.clientHeight + this.section2Dom.clientHeight + this.section3Dom.clientHeight;
        let section1Scroll = scrollY - this.keepDesignAspectSh;
        let moveHeight = this.keepDesignAspectSh / 1.5;
        let moveHeight2 = this.keepDesignAspectSh * 1.2;
        let current = section1Scroll / moveArea;
        if(current > 1) current = 1;
        let bg1ScrollY = moveHeight - moveHeight * current;

        let bg3ScrollY = moveHeight2 - moveHeight2 * current - this.adjustJapanMapY;
        this.bg1Dom.style.transform = `translate3d(0, ${bg1ScrollY}px, 0)`;
        this.bg3Dom.style.transform = `translate3d(0, ${bg3ScrollY}px, 0)`;
    }

    scrollHandler(){
        let scrollY = this.pack.scrollY;
        this.scrollBG();

        if(!this.enabled) return;
        if(!this.enabledScroll) return;
        if(this.isInTransition) return;
        
        if(this.currentSection === 0){
            if( scrollY > 0) {
                this.transitSection(1, this.sh75per);
                this.heroDom.classList.add('scale_down');
                this.h1SubTit.classList.add('fade_out');
                gsap.delayedCall(.6, ()=>{
                    this.h1SubTit.classList.remove('fade_out');
                    this.h1SubTit.classList.add('scale_down');
                });
            }
        }else if(this.currentSection === 1) {
            if (scrollY < this.sh75per) {
                //back to hero
                this.transitSection(0, 0);
                this.heroDom.classList.remove('scale_down');
                this.h1SubTit.classList.add('fade_out');
                gsap.delayedCall(.6, ()=>{
                    this.h1SubTit.classList.remove('scale_down');
                    this.h1SubTit.classList.remove('fade_out');
                });
            }
        }

        let section3Top = this.section3Dom.getBoundingClientRect().top;

        if(!this.section2Dom.classList.contains('show')) {
            if(this.section2List.getBoundingClientRect().top < this.shh){
                this.section2Dom.classList.add('show');
                this.section2H2.classList.add('show');
            }
        }

        if(!this.section3H2.classList.contains('show')){
            if(section3Top <= this.shh){
                this.section3H2.classList.add('show');
                this.section3Dom.classList.add('show');
            }
        }
    }

    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
        this.sh75per = this.sh * .75;
        this.keepDesignAspectSh = (this.sw >= this.pack.BP) ? this.sw * .625 : this.sw * 2.164;

        this.adjustJapanMapY = this.sw >= this.pack.BP ? 200 * this.sw / 1440 : 0;
    }
}