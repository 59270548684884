import TemplateContents from './TemplateContents';
import {gsap} from "gsap";

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
    }

    setDom(){
        super.setDom();

        this.section1Dom = document.querySelector('main.contact_thanks .section1');
        this.section1H2Pathes = document.querySelectorAll('main.contact_thanks .section1 h2 svg path');
    }

    initEvents(){
        super.initEvents();
    }

    setTransitionDelay(){
        let singleDelay = .08;
        let delayAll = singleDelay * (this.section1H2Pathes.length - 1);
        for( let i = 0, len = this.section1H2Pathes.length; i < len; i++ ){
            let item = this.section1H2Pathes[i];
            let delay = delayAll - (singleDelay * i);
            item.style.transitionDelay = delay + 's';
        }
    }

    start(isLanding){
        let delay;
        if(isLanding){
            delay = .01;
        }else{
            delay = .5;
        }

        this.setTransitionDelay();

        gsap.delayedCall(delay, ()=>{
            this.section1Dom.classList.add('show');
        });
    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
    }
}