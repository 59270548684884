import TemplateContents from './TemplateContents';
import {gsap} from "gsap";

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();


        for( let i = 0, len = this.tabs.length; i < len; i++ ){
            let item = this.tabs[i];
            item.removeEventListener('click', this.bindClickTabHandler[i]);
        }

        document.querySelector('body').classList.remove('page-contact')

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    destructAfterContentsOut(){

    }

    setVars(){
        super.setVars();
    }

    setDom(){
        super.setDom();

        this.mainDom = document.querySelector('main.contact');
        this.section1Dom = document.querySelector('main.contact .section1');
        this.tabs = document.querySelectorAll('main.contact .section1 .tab li');
        this.section1H2Pathes = document.querySelectorAll('main.contact .section1 h2 svg path');
        this.formContainers = document.querySelectorAll('main.contact .section1 .form_container .form');

        document.querySelector('body').classList.add('page-contact');

        const select = document.querySelector('[data-name="recipient"] select');
        if (select) {
          select.setAttribute('aria-invalid', 'true')
        }
        

        const forms = document.querySelectorAll('.wpcf7 form')

        if (forms) {
          forms.forEach((form) => {
            const submitButton = form.querySelector('input[type="submit"]')
            const privacyCheckbox = form.querySelector(
              'input[name="contact-privacy"]'
            )
            if (submitButton && privacyCheckbox) {
              // フィールドにblurイベントを追加してバリデーションを実行
              form
                .querySelectorAll('input, textarea, select')
                .forEach(function (element) {
                  element.addEventListener('blur', function () {
                    // blurイベント時にContact Form 7のvalidateクラスの更新を確認
                    let event = new Event('change', {bubbles: true})
                    element.dispatchEvent(event)
                    updateSubmitButtonState()
                  })
                })

              // チェックボックスの状態変化も検知
              privacyCheckbox.addEventListener(
                'change',
                updateSubmitButtonState
              )

              function updateSubmitButtonState() {
                const hasEmptyFields = Array.from(
                  form.querySelectorAll('[aria-required="true"]')
                ).some((field) => field.value.trim() === '')

                // プライバシーチェックボックスがチェックされ、かつすべてのフィールドが入力済みの場合
                if (privacyCheckbox.checked && !hasEmptyFields) {
                  submitButton.classList.add('enabled')
                  submitButton.disabled = false
                } else {
                  submitButton.classList.remove('enabled')
                  submitButton.disabled = true
                }
              }
            }
          })
        }
    }

    initEvents(){
        super.initEvents();

        this.bindClickTabHandler = [];
        for( let i = 0, len = this.tabs.length; i < len; i++ ){
            let item = this.tabs[i];
            this.bindClickTabHandler[i] = this.clickTabHandler;
            item.addEventListener('click', {handleEvent: this.bindClickTabHandler[i], sc:this, id:i});
        }
    }

    clickTabHandler(event){
        let sc = this.sc;
        let id = this.id;

        if(id === 0){
            sc.formContainers[0].classList.add('current');
            sc.tabs[0].classList.add('current');
            sc.formContainers[1].classList.remove('current');
            sc.tabs[1].classList.remove('current');

        }else{
            sc.formContainers[0].classList.remove('current');
            sc.tabs[0].classList.remove('current');
            sc.formContainers[1].classList.add('current');
            sc.tabs[1].classList.add('current');
        }
    }

    setTransitionDelay(){
        let singleDelay = .08;
        let delayAll = singleDelay * (this.section1H2Pathes.length - 1);
        for( let i = 0, len = this.section1H2Pathes.length; i < len; i++ ){
            let item = this.section1H2Pathes[i];
            let delay = delayAll - (singleDelay * i);
            item.style.transitionDelay = delay + 's';
        }
    }

    start(isLanding){
        let delay;
        if(isLanding){
            delay = .01;
        }else{
            delay = .5;
        }

        this.setTransitionDelay();

        gsap.delayedCall(delay, ()=>{
            this.section1Dom.classList.add('show');
        });
    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
    }
}