import TemplateContents from './TemplateContents';

import {gsap, Quart} from "gsap";
import GUI from 'lil-gui';
import * as THREE from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import vertex from './shaders/vertex_mask3.glsl';
import fragment from './shaders/frag_mask3.glsl';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        // super.init(true);

        this.sw, this.sh;
        this.enterframeID;

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    setCanvasDOM(isTop){
        this.isTop = isTop;

        if(isTop) this.canvasContainer = document.querySelector('main.top .section2 .mask_target');
        else this.canvasContainer = document.querySelector('main.about .section2 .mask_target');

        this.canvas = document.createElement("canvas");
        this.canvasContainer.appendChild(this.canvas);
        this.sw = this.canvasContainer.offsetWidth;
        this.sh = this.canvasContainer.offsetHeight;
        this.canvas.width = this.sw;
        this.canvas.height = this.sh;

        this.setVars();
        this.setDom();
        this.bindResizeHandler = this.resizeHandler.bind(this);
        this.hasTouch = ("ontouchstart" in window);
        this.resizeEvent = this.hasTouch ? 'orientationchange' : 'resize';

        this.initEvents();
        // this.initGUI();
        this.initWebGL();
        this.initMesh();
        this.resetVideo();
    }

    reset(isTop){
        // super.reset();

        this.setVars();
        this.isTop = isTop;
        if(this.isTop) this.canvasContainer = document.querySelector('main.top .section2 .mask_target');
        else this.canvasContainer = document.querySelector('main.about .section2 .mask_target');

        this.canvasContainer.appendChild(this.canvas);
        this.sw = this.canvasContainer.offsetWidth;
        this.sh = this.canvasContainer.offsetHeight;
        this.canvas.width = this.sw;
        this.canvas.height = this.sh;

        this.setDom();
        this.initEvents();
        this.resetVideo();
        this.resizeHandler();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.videoDOM.removeEventListener('ended', this.bindEndedVideoHandler);

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    destructAfterContentsOut(){
        this.videoTexture.dispose();
        this.material.uniforms.uVideoTexture.value = null;
        this.videoDOM.src = '';
        this.render();

        this.canvas.parentNode.removeChild(this.canvas);
    }

    setVars(){
        super.setVars();
        // this.endedVideo = false;

        this.pointer = new THREE.Vector2();
        this.pointer2 = new THREE.Vector2();
        this.easePointer = new THREE.Vector2(0, 0);
        this.easePointer2 = new THREE.Vector2(0, 0);

        this.canvasX, this.canvasY;
    }

    setDom(){
        super.setDom();
        if(this.isTop) this.videoDOM = document.querySelector('main.top .section2 video');
        else this.videoDOM = document.querySelector('main.about .section2 video');
        this.sectionChild = document.querySelector('main.top .section2 .section_child');
    }

    initEvents(){
        super.initEvents();

        // this.bindEndedVideoHandler = this.endedVideoHandler.bind(this);
        // this.videoDOM.addEventListener('ended', this.bindEndedVideoHandler);
    }

/*
    endedVideoHandler(event){
        this.endedVideo = true;
    }
*/

    start(isTop){
        let delay = isTop ? 1.3 : 0;
        gsap.delayedCall(delay, ()=>{
            this.videoDOM.play();
        });
    }

    initGUI(){
        this.settings = {
            scale: 1,
            adjustX: 0,
            adjustY: 0,
        };
        const gui = this.gui = new GUI();
        gui.add(this.settings, 'scale', 0.001, 2.0, 0.01);
        gui.add(this.settings, 'adjustX', -.5, .5, 0.01);
        gui.add(this.settings, 'adjustY', -.5, .5, 0.01);
    }

    initWebGL() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            alpha : true,
            antialias : true
        });
        // this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setPixelRatio(1);
        this.renderer.setSize(this.sw, this.sh);
        this.renderer.autoClear = false;

        this.scene = new THREE.Scene();

        let frustumSize = 1;

        this.camera = new THREE.OrthographicCamera(frustumSize / -2, frustumSize / 2, frustumSize / 2, frustumSize / -2, -1000, 1000);
        this.camera.position.set(0,0,2);

        // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    }

    initMesh(){
        this.texture = this.pack.textures.section2_mask_bg;

        let textureAspect = 1600 / 600;
        let scale, adjustX, adjustY;

        if(this.sw >= this.pack.BP) {
            scale = 1;
            adjustX = 0;
            adjustY = 0;
        }else {
            scale = 1;
            adjustX = 0;
            adjustY = 0;
        }

        let geometry = new THREE.PlaneGeometry( 1, 1, 32 );
        let material = this.material = new THREE.ShaderMaterial({
            side: THREE.DoubleSide,
            uniforms:{
                uViewport: {value: new THREE.Vector2(this.sw, this.sh)},
                uTexture: {value: this.texture},
                uTexturePosition: {value: new THREE.Vector2(0, 0)},
                uTextureSize: {value: new THREE.Vector2(1600, 800)},
                uVideoTexture: {value: null},
                uTextureAspect: {value: textureAspect},
                uScale: {value: scale},
                uAdjust: {value: new THREE.Vector2(adjustX, adjustY)},

            },
            vertexShader: vertex,
            fragmentShader: fragment,
            transparent: true
        });

        let plane = new THREE.Mesh( geometry, material );
        this.scene.add( plane );
    }

    resetVideo(){
        this.videoTexture = new THREE.VideoTexture(this.videoDOM);
        this.material.uniforms.uVideoTexture.value = this.videoTexture;
        this.render();
    }

    scrollHandler(){

    }

    render(){
        // this.material.uniforms.uScale.value = this.settings.scale;
        // this.material.uniforms.uAdjust.value = new THREE.Vector2(this.settings.adjustX, this.settings.adjustY);
        this.renderer.render(this.scene, this.camera);
    }

    enterframe(){

    }

    enterframeThinOut(){
        if(this.isTop && !this.pack.hasTouch){
            let rect = this.canvas.getBoundingClientRect();
            this.canvasX = rect.left;
            this.canvasY = rect.top;

            let x = this.pack.top.pointer.x - this.canvasX;
            let y = this.pack.top.pointer.y - this.canvasY;

            this.pointer2.x = x - this.swh;
            this.pointer2.y = y - this.shh;

            this.easePointer2.x += (-this.pointer2.x * .05 - this.easePointer2.x) * .08;
            this.easePointer2.y += (-this.pointer2.y * .05 - this.easePointer2.y) * .08;
            this.sectionChild.style.transform = `translate3d(${this.easePointer2.x}px, ${this.easePointer2.y}px, 0)`;
        }

        // if(!this.endedVideo)this.render();
        this.render();
    }

    executeResize() {
        // super.executeResize();
        this.sw = window.innerWidth;
        this.sh = window.innerHeight;

        let borderAspect = 16 / 9;
        let screenAspect = this.sw / this.sh;

        let videoAspect = 1600 / 600;
        let videoInverseAspect = 1 / videoAspect;

        if(this.isTop){
            if(this.sw >= this.pack.BP){
                if(screenAspect > borderAspect){
                    //縦基準レイアウト
                    this.sh = this.sh * 0.69;
                    this.sw = this.sh * videoAspect;
                }else{
                    //横基準レイアウト
                    this.sw = this.sw * 1.12;
                    this.sh = this.sw * videoInverseAspect;
                }
            }else{
                // this.sw = this.sw * 1.58;
                this.sw = this.sw * 1.34;
                this.sh = this.sw * videoInverseAspect;
            }

        }else{
            if(this.sw >= this.pack.BP) {
                this.sw = this.sw * 1.04;
                this.sh = this.sw * videoInverseAspect;
            }else{
                this.sw = this.sw * 1.67;
                this.sh = this.sw * videoInverseAspect;
            }
        }

        this.swh = this.sw / 2;
        this.shh = this.sh / 2;

        if(!this.canvas) return;

        this.canvas.width = this.sw;
        this.canvas.height = this.sh;

        if(!this.camera) return;

        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.sw, this.sh);

        this.material.uniforms.uViewport.value = new THREE.Vector2(this.sw, this.sh);
        this.render();
    }
}