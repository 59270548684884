import {gsap, Power1, Power4, Expo, Quart, Quint} from "gsap";

import TemplateContents from './TemplateContents';
import TopBG from './TopBG';
import TopMask1 from './TopMask1';
import TopMask2 from './TopMask2';
import TopMask3 from './TopMask3';
import TopMask4 from './TopMask4';
import * as THREE from "three";


export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init(true);

        //最初はbgのみnewするそれ以外のWebGLオブジェクトはset関数で実行
        this.bg = new TopBG();

        this.initEvents();
        this.initSections();

        this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);
    }

    set(){
        //読み込み完了したら時間差設定
        this.bg.set();
        this.mask1 = new TopMask1();
        this.mask2 = new TopMask2();
        if(this.pack.mask3) {
            this.mask3 = this.pack.mask3;
            this.mask3.reset(true);
        }else {
            this.mask3 = this.pack.mask3 = new TopMask3(true);
            this.mask3.setCanvasDOM(true);
        }
        this.mask4 = new TopMask4();
    }

    reset(){
        super.reset();

        this.setVars();
        this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);

        this.initSections();

        this.bg.reset();
        this.mask1.reset();
        this.mask2.reset();
        this.mask3.reset(true);
        this.mask4.reset();
    }

    destruct(){
        super.destruct();

        // this.pack.header.changeColor(false);

        document.removeEventListener('mousemove', this.bindPointerMoveHandler);

        this.pack.common.removeScrollTarget(this);
        this.pack.common.removeEnterframeTarget(this);
    }

    destructAfterContentsOut(){
        this.bg.destructAfterContentsOut();
        this.mask1.destructAfterContentsOut();
        this.mask2.destructAfterContentsOut();
        this.mask3.destructAfterContentsOut();
        this.mask4.destructAfterContentsOut();
    }

    setVars(){
        super.setVars();

        this.transitionTween;
        this.sectionHero12ScrollableHeight;
        this.sectionHero12ScrollableSingleHeight;

        this.enabled = false;
        this.enabledScroll = false;
        this.currentSection = 0;        //0->hero, 1->section1, 2->section3
        this.transitionDuration = 1.0;
        this.transitionEase = Quint.easeInOut;

        this.isInTransition = false;

        this.targetFrictionScroll = 0;
        this.isFriction = false;
        this.frictionRatio = .6;
        this.FRICTION_HEIGHT = 10;
        this.FRICTION_HEIGHT_SP = 8;
        this.isForward = true;

        this.frictionRatio = .6;
        this.delta = 0;
        this.currentDelta = 0;
        this.touchStartY = 0;

        this.enabledRendering1 = false;
        this.enabledRendering2 = false;
        this.enabledRendering3 = false;
        this.enabledRendering4 = false;


        this.heroStartPositions = [
            {x: 0, y: 0},
        ];

        this.heroEndPositions = [
            {x: -160, y: -100},
        ];

        this.section1StartPositions = [
            {x: 160, y: 100},
            {x: 0, y: 0},
        ];

        this.section1EndPositions = [
            {x: 0, y: 0},
            {x: 160, y: -100},
        ];

        this.section2StartPositions = [
            {x: -160, y: 100},
        ];

        this.section2EndPositions = [
            {x: 0, y: 0},
        ];

        this.heroX = 0;
        this.heroY = 0;
        this.heroXEase = this.heroX;
        this.heroYEase = this.heroY;

        this.section1X = 0;
        this.section1Y = 0;
        this.section1XEase = this.section1X;
        this.section1YEase = this.section1Y;

        this.section2X = 0;
        this.section2Y = 0;
        this.section2XEase = this.section2X;
        this.section2YEase = this.section2Y;

        this.pointer = new THREE.Vector2();
    }

    setDom(){
        super.setDom();

        this.heroDOM = document.querySelector('main.top .hero');
        this.heroH2ChunksPC = document.querySelectorAll('main.top .hero h2 .chunks.pc .chunk');
        this.heroH2ChunksSP = document.querySelectorAll('main.top .hero h2 .chunks.sp .chunk');

        this.section1DOM = document.querySelector('main.top .section1');
        this.section1Path = document.querySelectorAll('main.top .section1 .copy path, main.top .section1 .copy_lower path');

        this.section2DOM = document.querySelector('main.top .section2');
        this.section2PathPC = document.querySelectorAll('main.top .section2 .copy.pc svg > path');
        this.section2LowerPathPC = document.querySelector('main.top .section2 .copy_lower.pc svg > path');
        this.section2PathPC_CharO = document.querySelector('main.top .section2 .copy.pc svg .char_o');
        this.section2PathSP = document.querySelectorAll('main.top .section2 .copy.sp svg > path');
        this.section2LowerPathSP = document.querySelector('main.top .section2 .copy_lower.sp svg > path');
        this.section2PathSP_CharO = document.querySelector('main.top .section2 .copy.sp svg .char_o');

        this.section3DOM = document.querySelector('main.top .section3');
        this.section3Items = document.querySelectorAll('main.top .section3 .links li a .tit .item');
        this.section3Subtits = document.querySelectorAll('main.top .section3 .links li a .sub_tit');

        this.section4DOM = document.querySelector('main.top .section4');
    }

    initEvents(){
        super.initEvents();
        this.bindPointerMoveHandler = this.pointerMoveHandler.bind(this);
        document.addEventListener('mousemove', this.bindPointerMoveHandler);
    }

    pointerMoveHandler(event){
        let x = event.clientX;
        let y = event.clientY;

        this.pointer.x = x;
        this.pointer.y = y;
    }

    setTransitionDelay(){
        this.setHeroTransitionDelay();
        this.setSection1TransitionDelay();
        this.setSection2TransitionDelay();
        this.setSection3TransitionDelay();
    }

    setHeroTransitionDelay(){
        for( let i = 0, len = this.heroH2ChunksPC.length; i < len; i++ ){
            let chunk = this.heroH2ChunksPC[i];
            let items = chunk.querySelectorAll('.item');
            for( let j = 0, len = items.length; j < len; j++ ){
                let item = items[j];
                item.style.transitionDelay = i * .11 + j * .025 + 's';
            }
        }

        for( let i = 0, len = this.heroH2ChunksSP.length; i < len; i++ ){
            let chunk = this.heroH2ChunksSP[i];
            let items = chunk.querySelectorAll('.item');
            for( let j = 0, len = items.length; j < len; j++ ){
                let item = items[j];
                item.style.transitionDelay = i * .11 + j * .025 + 's';
            }
        }
    }

    setSection1TransitionDelay(){
        //NodeListからArrayに変換
        let section1Path = [];
        Array.prototype.forEach.call(this.section1Path, (node) => {
            section1Path.push(node);
        });

        const lastElement = section1Path.pop();
        section1Path.splice(9, 0, lastElement); //10番目に挿入

        let singleDelay = .04;
        let delayAll = singleDelay * section1Path.length;
        for( let i = 0, len = section1Path.length; i < len; i++ ){
            let path = section1Path[i];
            let delay = delayAll - (singleDelay * i);
            path.style.transitionDelay = delay + 's';
        }
    }

    setSection2TransitionDelay(){
        let singleDelay = .02;
        let delayAll = singleDelay * this.section2PathPC.length;
        for( let i = 0, len = this.section2PathPC.length; i < len; i++ ){
            let id = i;
            if(i >= 4) id = i + 1;
            let path = this.section2PathPC[i];
            let delay = delayAll - (singleDelay * id);
            path.style.transitionDelay = delay + 's';
        }

        this.section2PathPC_CharO.style.transitionDelay = '2.0s';
        this.section2LowerPathPC.style.transitionDelay = '1.22s';

        delayAll = singleDelay * this.section2PathSP.length;
        for( let i = 0, len = this.section2PathSP.length; i < len; i++ ){
            let id = i;
            if(i >= 4) id = i + 1;
            let path = this.section2PathSP[i];
            let delay = delayAll - (singleDelay * id);
            path.style.transitionDelay = delay + 's';
        }

        this.section2PathSP_CharO.style.transitionDelay = '2.0s';
        this.section2LowerPathSP.style.transitionDelay = '1.22s';
    }

    setSection3TransitionDelay(){
        let singleDelay = .04;
        let singleDelay2 = .4;
        for( let i = 0, len = this.section3Items.length; i < len; i++ ){
            let item = this.section3Items[i];
            let delay = singleDelay * i;
            item.style.transitionDelay = delay + 's';
        }

        for( let i = 0, len = this.section3Subtits.length; i < len; i++ ){
            let item = this.section3Subtits[i];
            let delay = singleDelay2 * i;
            item.style.transitionDelay = .35 + delay + 's';
        }
    }

    initSections(){
        gsap.set(this.section1DOM, {x: '160rem', y: '100rem'});
        gsap.set(this.section2DOM, {x: '-160rem', y: '100rem'});
    }

    start(isLanding){
        let delay, delay2;
        if(isLanding){
            delay = .01;
            delay2 = 2.01;
        }else{
            delay = 1;
            delay2 = 3;
        }

        this.setTransitionDelay();
        this.enabled = true;
        this.enabledRendering1 = true;

        this.mask2.render();
        this.mask3.render();
        this.mask4.render();

        this.bg.start();
        this.mask1.start(isLanding);
        this.enterframeThinOut();

        gsap.delayedCall(delay, ()=>{
            this.heroDOM.classList.add('show');
        });

        document.documentElement.classList.add('lock');
        gsap.delayedCall(delay2, ()=>{
            this.resizeHandler();
            this.enabledScroll = true;
            document.documentElement.classList.remove('lock');
        });
    }

    transitSection(current, targetY){
        this.isInTransition = true;
        this.currentSection = current;
        document.documentElement.classList.add('lock');
        this.pack.anchorScroll(targetY, this.transitionDuration, this.transitionEase, ()=>{
            document.documentElement.classList.remove('lock');
            this.isInTransition = false;
        });
    }

    setFriction(targetFrictionScroll, scrollY, isForward){
        let friction = (this.sw >= this.pack.BP) ? this.FRICTION_HEIGHT : this.FRICTION_HEIGHT_SP;
        let next = (isForward) ? friction : -friction;
        this.isForward = isForward;
        if(!this.isFriction){
            this.targetFrictionScroll = targetFrictionScroll;
            this.nextFrictionScroll = this.targetFrictionScroll + next;
            this.currentEaseFrictionScroll = scrollY;
            // trace(this.targetFrictionScroll, this.nextFrictionScroll, this.currentEaseFrictionScroll);
            this.isFriction = true;
            // trace('setFriction', this.currentSection, isForward);
            document.documentElement.classList.add('lock');
        }
    }

    resetFriction(){
        // trace('resetFriction');
        document.documentElement.classList.remove('lock');
        this.isFriction = false;
        this.currentDelta = 0;
        this.currentEaseFrictionScroll = this.targetFrictionScroll;
    }

    backToHero(){
        let dr = this.transitionDuration;
        this.enabledRendering1 = true;
        this.bg.backToHero();

        gsap.to(this.heroDOM, dr, {delay:.1, x:'0', y:'0', ease:this.transitionEase, onComplete:()=>{
                this.mask1.setCanvasPosition();
            }});

        if(this.transitionTween) this.transitionTween.kill();
        this.transitionTween = gsap.to(this.section1DOM, dr, {x: '160rem', y: '100rem', ease:this.transitionEase, onComplete:()=>{
                this.enabledRendering2 = false;
            }});
    }

    startSection1(){
        let dr = this.transitionDuration;
        this.enabledRendering2 = true;
        this.bg.startSection1();
        gsap.delayedCall(this.transitionDuration + .0, ()=>{
            if(!this.section1DOM.classList.contains('show')) {
                this.section1DOM.classList.add('show');
                this.mask2.start();
                gsap.delayedCall(2, ()=>{
                    this.section1DOM.classList.remove('delay');
                });
            }
        });

        gsap.to(this.heroDOM,   dr, {x:'-160rem', y:'-100rem', ease:this.transitionEase});
        if(this.transitionTween) this.transitionTween.kill();
        this.transitionTween = gsap.to(this.section1DOM, dr, {delay:.1, x:'0', y:'0', ease:this.transitionEase, onComplete:()=>{
            this.enabledRendering1 = false;
        }});

        // gsap.to(this.heroDOM, {duration: 2, motionPath:{path:[{x:'-160rem', y:'-50rem'}, {x:'-160rem', y:'-100rem'}]}, ease:Quart.easeInOut});
        // gsap.to(this.section1DOM, {duration: 2, motionPath:{path:[{x:'80rem', y:'100rem'}, {x:'0', y:'0'}]}, ease:Quart.easeInOut});
    }

    backToSection1(){
        let dr = this.transitionDuration;
        this.enabledRendering2 = true;
        this.bg.backToSection1();
        gsap.to(this.section1DOM, dr, {delay:.1, x: '0', y: '0', ease:this.transitionEase});
        if(this.transitionTween) this.transitionTween.kill();
        this.transitionTween = gsap.to(this.section2DOM, dr, {x: '-160rem', y: '100rem', ease:this.transitionEase, onComplete:()=>{
                this.enabledRendering3 = false;
            }});
    }
    

    startSection2(){
        let dr = this.transitionDuration;
        this.bg.startSection2();
        this.enabledRendering3 = true;
        gsap.delayedCall(this.transitionDuration + .0, ()=>{
            if(!this.section2DOM.classList.contains('show')) {
                this.section2DOM.classList.add('show');
                this.mask3.start(true);

                gsap.delayedCall(2, ()=>{
                    this.section2DOM.classList.remove('delay');
                });
            }
        });

        gsap.to(this.section1DOM, dr, {x: '160rem', y: '-100rem', ease:this.transitionEase});
        if(this.transitionTween) this.transitionTween.kill();
        this.transitionTween = gsap.to(this.section2DOM, dr, {delay:.1, x:'0', y:'0', ease:this.transitionEase, onComplete:()=>{
                this.enabledRendering2 = false;
            }});
    }

    startSection3(){
        // this.bg.startSection3();
    }

    scrollHandler(){
        if(!this.enabled) return;
        if(!this.enabledScroll) return;
        if(this.isInTransition) return;

        let scrollY = this.pack.scrollY;

        if(this.currentSection === 0){
            if( scrollY > 0) {
                //move to section1
                this.setFriction(0, scrollY, true);
                if(scrollY > this.nextFrictionScroll){
                    this.isFriction = false;
                    this.transitSection(1, this.sh);
                    this.startSection1();
                }
            }
        }else if(this.currentSection === 1){
            if(scrollY < this.sh) {
                //back to hero
                this.setFriction(this.sh, scrollY, false);
                if(scrollY < this.nextFrictionScroll){
                    this.transitSection(0, 0);
                    this.backToHero();
                }
            }else if(scrollY > this.sh){
                //move to section2
                this.setFriction(this.heroDOM.clientHeight + this.section1DOM.clientHeight - this.sh, scrollY, true);
                if(scrollY > this.nextFrictionScroll){
                    this.isFriction = false;
                    this.transitSection(2, this.sh * 2);
                    this.startSection2();
                }
            }
        }else if(this.currentSection === 2){
            if(scrollY < this.sh * 2) {
                //back to section1
                this.setFriction(this.heroDOM.clientHeight + this.section1DOM.clientHeight, scrollY, false);
                if(scrollY < this.nextFrictionScroll){
                    this.isFriction = false;
                    this.transitSection(1, this.sh);
                    this.backToSection1();
                }
            }
        }

        this.bg.scrollHandler();

        if(scrollY > this.sh * 3 + this.shhh && scrollY <= this.sh * 4 + this.shhh){
            //show section3
            this.enabledRendering3 = false;
            if(!this.section3DOM.classList.contains('show')) {
                this.section3DOM.classList.add('show');
                gsap.delayedCall(1, () => {
                    this.section3DOM.classList.add('no_delay');
                });
            }
        }else if(this.currentSection === 2 && scrollY <= this.sh * 3 + this.shhh){
            this.enabledRendering4 = false;
            this.enabledRendering3 = true;
        }

        if(scrollY > this.sh * 4 + this.shhh){
            //show section4
            this.enabledRendering4 = true;
            if(!this.mask4.startedAnimation) {
                this.mask4.start();
                this.section4DOM.classList.add('show');
            }
        }
    }


    enterframe(){
        if(!this.enabled) return;
        this.bg.enterframe();
    }

    enterframeThinOut(){
        if(!this.enabled) return;
        // let ease = 1 / 3;

        // this.heroXEase += (this.heroX - this.heroXEase) * ease;
        // this.heroYEase += (this.heroY - this.heroYEase) * ease;
        // this.heroDOM.style.transform = `translate3d(${this.heroXEase}rem, ${this.heroYEase}rem, 0)`;
        // this.heroDOM.style.transform = `translate3d(${this.heroX}rem, ${this.heroY}rem, 0)`;

        /*

                this.section1XEase += (this.section1X - this.section1XEase) * ease2;
                this.section1YEase += (this.section1Y - this.section1YEase) * ease2;
                this.section1.style.transform = `translate3d(${this.section1XEase}rem, ${this.section1YEase}rem, 0)`;

                this.section2XEase += (this.section2X - this.section2XEase) * ease2;
                this.section2YEase += (this.section2Y - this.section2YEase) * ease2;
                this.section2.style.transform = `translate3d(${this.section2XEase}rem, ${this.section2YEase}rem, 0)`;

                // this.section1.style.transform = `translate3d(${secX}rem, ${secY}rem, 0)`;
                // this.section2.style.transform = `translate3d(${secX}rem, ${secY}rem, 0)`;*/



        this.bg.enterframeThinOut();
        if(this.enabledRendering1) this.mask1.enterframeThinOut();
        if(this.enabledRendering2) this.mask2.enterframeThinOut();
        if(this.enabledRendering3) this.mask3.enterframeThinOut();
        if(this.enabledRendering4) this.mask4.enterframeThinOut();


        if(this.isFriction){
            this.currentDelta += (this.delta - this.currentDelta) * .3;
            this.delta *= this.frictionRatio;
            let current = this.targetFrictionScroll + this.currentDelta;
            this.currentEaseFrictionScroll += (current - this.currentEaseFrictionScroll) * .3;
            // window.scrollTo(0, this.currentEaseFrictionScroll);

            let dif = Math.abs(this.currentEaseFrictionScroll - this.targetFrictionScroll);

            if(dif < .5) {
                this.resetFriction();
                window.scrollTo(0, this.targetFrictionScroll);
            }
        }
    }

    executeResize() {
        super.executeResize();
        this.shhh = this.shh >> 2;
        this.sectionHero12ScrollableHeight = this.heroDOM.offsetHeight + this.section1DOM.offsetHeight + this.section2DOM.offsetHeight;
        this.sectionHero12ScrollableSingleHeight = this.sectionHero12ScrollableHeight / 3;
    }
}