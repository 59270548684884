import Config from "./Config";
import {gsap, Expo, Quart, Quad, Sine, Linear} from "gsap";

import Top from "./Top";
import About from './About';
import Service from "./Service";
import News from "./News";
import NewsDetails from "./NewsDetails";
import Contact from './Contact';
import ContactThanks from "./ContactThanks";
import PrivacyPolicy from "./PrivacyPolicy";
import Page404 from "./Page404";

class BarbaCustom {
    constructor() {
        this.init();
    }

    init(){
        let sc = this;
        this.setVars();
        this.setDom();
        this.initEvents();
        this.initTransition();

        Barba.Pjax.getTransition = function() {
            return sc.transition;
        };

        Barba.Pjax.start();
        Barba.Prefetch.init();
        this.cancelSameLinks();
        // this.preventCheck();
    }

    /*    preventCheck(){
            //TODO この関数内の処理は画面クリックしただけでwindow.historyが追加されていってしまったため、barba.jsに直接追加記述 2020/9/8 by Masayuki Daijima
            let sc = this;
            Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;
            Barba.Pjax.preventCheck = function(evt, element) {
                if(element){
                    // アンカーリンクであり同一ページでなければPjaxを有効に
                    let url = location.protocol + '//' + location.host + location.pathname;
                    let extract_hash = element.href.replace(/#.*$/,"");

                    // if (element.href.startsWith(location.protocol + '//' + location.host)) {}
                    if (element.target === "_blank") return false;
                    if (element.href.indexOf('#') > -1 &&  extract_hash != url ) return true;
                    if (element.href.startsWith('mailto:')) return false;
                }
                return true;
            };
        }
    */

    setVars(){
        this.pack = Config.getInstance();
        this.pack.barba = this;
        this.before;
    }

    setDom(){
        this.wrapper = document.querySelector('#barba-wrapper');
        this.barbaContainer = document.querySelector('.barba-container');
        this.transition = document.querySelector('#transition');
        this.scrollWrapper = document.querySelector('.scroll-wrapper');
    }

    initEvents(){
        Barba.Dispatcher.on('newPageReady', this.newPageReadyHandler.bind(this));
        Barba.Dispatcher.on('linkClicked', this.linkClickedHandler.bind(this));
        Barba.Dispatcher.on('initStateChange', this.initStateChangeHandler.bind(this));
        Barba.Dispatcher.on('transitionCompleted', this.transitionCompletedHandler.bind(this));
    }

    initStateChangeHandler(currentStatus){
        let namespace = this.pack.current;
        let pack = this.pack;

        trace('initStateChange', namespace, Barba.HistoryManager.history.length);

        if ( Barba.HistoryManager.history.length === 1 ) {  // ファーストビュー
            return; // この時に更新は必要ありません
        }

        pack.common.lockScroll = true;

        if (namespace === 'top') {
            this.pack.top.destruct();
        }else if(namespace === 'about'){
            this.pack.about.destruct();
        }else if(namespace === 'service'){
            this.pack.service.destruct();
        }else if(namespace === 'news'){
            this.pack.news.destruct();
        }else if(namespace === 'news_details'){
            this.pack.news_details.destruct();
        }else if(namespace === 'contact'){
            this.pack.contact.destruct();
        }else if(namespace === 'contact_thanks'){
            this.pack.contact_thanks.destruct();
        }else if(namespace === 'page404'){
            this.pack.page404.destruct();
        }
    }

    initTransition(){
        let sc = this;
        let pack = this.pack;

        this.transition = Barba.BaseTransition.extend({
            start(){
                this.newContainerLoaded = false;
                this.fadeOutCompleted = false;
                sc.isTransition = true;
                pack.past = pack.current;

                //oldContainerが隠れるトランジション終了後のcallback
                let callback = ()=>{
                    pack.header.closeMenuExternal();
                    this.fadeOutCompleted = true;
                    sc.endContentsOutHandler(pack.past);
                    this.checkReady();
                };

                this.contentsOut();

                gsap.delayedCall(.1, ()=>{
                    pack.common.startTransitionIn(callback);
                });
            },

            contentsOut(){
                // this.startSerial()
                //     .then(this.newContainerLoading)
                //     .then(this.initContainer.bind(this));

                this.newContainerLoading.then(this.initContainer.bind(this));
            },

            startSerial(){
                return new Promise(function(resolve){
                    resolve();
                });
            },

            initContainer(){
                if(!this.newContainer) {
                    //SPで遷移後のnewContainerを認識できずにエラーが出る時があるので.2秒待ってもう1回initContainerを呼ぶ
                    TweenMax.delayedCall(.2, this.initContainer.bind(this));
                    return;
                }

                //リンククリック時にnewContainerが表示される場合の処理
                if(this.newContainer) this.newContainer.style.display = "none";

                this.newContainerLoaded = true;
                this.checkReady();
            },

            checkReady(){
                //newContainerの読み込みが完了し、oldContainerが消え切るトランジション完了したかをチェック
                if(this.newContainerLoaded && this.fadeOutCompleted) {
                    this.contentsIn();
                }
            },

            contentsIn(){
                let namespace = pack.current;
                if(this.newContainer){
                    this.newContainer.style.display = "block";
                    this.newContainer.style.visibility = "visible";
                }
                this.done();

                if(namespace === 'top'){
                    if(pack.top) pack.top.reset();
                    else {
                        pack.top = new Top();
                        pack.top.set();
                    }
                    pack.top.start();
                }else if(namespace === 'about'){
                    if(pack.about) pack.about.reset();
                    else pack.about = new About();
                    pack.about.start();
                }else if(namespace === 'service'){
                    if(pack.service) pack.service.reset();
                    else pack.service = new Service();
                    pack.service.start();
                }else if(namespace === 'news'){
                    if(pack.news) pack.news.reset();
                    else pack.news = new News();
                    pack.news.start();
                }else if(namespace === 'news_details'){
                    if(pack.news_details) pack.news_details.reset();
                    else pack.news_details = new NewsDetails();
                    pack.news_details.start();
                }else if(namespace === 'contact'){
                    if(pack.contact) pack.contact.reset();
                    else pack.contact = new Contact();
                    pack.contact.start();
                }else if(namespace === 'contact_thanks'){
                    if(pack.contact_thanks) pack.contact_thanks.reset();
                    else pack.contact_thanks = new ContactThanks();
                    pack.contact_thanks.start();
                }else if(namespace === 'privacy'){
                    if(pack.privacy) pack.privacy.reset();
                    else pack.privacy = new PrivacyPolicy();
                    pack.privacy.start();
                }else if(namespace === 'page404'){
                    if(pack.page404) pack.page404.reset();
                    else pack.page404 = new Page404();
                    pack.page404.start();
                }

                if(namespace === 'top'){
                    pack.footer.setFooter(true);
                }else{
                    pack.footer.setFooter(false);
                }

                // pack.header.resetHeader();
                pack.common.setCurrent();
                pack.common.lockScroll = false;

                this.resetScroll();

                //インラインJS
                this.addInlineJs();

                pack.common.resizeHandler();
                gsap.delayedCall(2, function(){
                    //駄目押しリサイズ
                    pack.common.resizeHandler();
                });

                pack.common.startTransitionOut();
                sc.isTransition = false;
            },

            resetScroll(){
                window.scrollTo(0,0);
                pack.common.resetScrollY();
            },

            addInlineJs(){
                let js = this.newContainer.querySelector("script");

                if(js != null){
                    let addJs = document.createElement("script");
                    addJs.innerHTML = js.innerHTML;
                    this.newContainer.appendChild(addJs);
                }
            }
        });
    }

    newPageReadyHandler(currentStatus, oldStatus, barbaContainer, newPageRawHTML){
        trace('newPageReady', currentStatus.namespace);

        this.before = this.pack.current;
        let namespace = this.pack.current = currentStatus.namespace;

        if ( Barba.HistoryManager.history.length === 1 ) {  // ファーストビュー
            return; // この時に更新は必要ありません
        }

        //metaタグの差し替え for News Details
        let head = document.head;
        let newPageRawHead = newPageRawHTML.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
        let newPageHead = document.createElement('head');
        newPageHead.innerHTML = newPageRawHead;
        let removeHeadTags = [
            "meta[name='post-id']",
        ].join(',');
        let headTags = head.querySelectorAll(removeHeadTags)
        for(let i = 0; i < headTags.length; i++ ){
            head.removeChild(headTags[i]);
        }
        let newHeadTags = newPageHead.querySelectorAll(removeHeadTags)

        for(let i = 0; i < newHeadTags.length; i++ ){
            head.appendChild(newHeadTags[i]);
        }

        // =============================================
        // Googleアナリティクスへ送信
/*        if (typeof gtag === 'function') {
            gtag('config', window.GA_MEASUREMENT_ID,{
                page_path: window.location.pathname
            });
        }*/
        // =============================================
    }

    linkClickedHandler(urrentStatus, event){
        // trace('linkClicked');
    }

    endContentsOutHandler(namespace){
        trace('endContentsOutHandler');

        if (namespace === 'top') {
            this.pack.top.destructAfterContentsOut();
        }else if(namespace === 'about'){
            this.pack.about.destructAfterContentsOut();
        }else if(namespace === 'service'){
            this.pack.service.destructAfterContentsOut();
        }else if(namespace === 'news'){
            this.pack.news.destructAfterContentsOut();
        }else if(namespace === 'news_details'){
            this.pack.news_details.destructAfterContentsOut();
        }else if(namespace === 'contact'){
            this.pack.contact.destructAfterContentsOut();
        }
    }

    transitionCompletedHandler(currentStatus, oldStatus, barbaContainer, newPageRawHTML){
        trace('transitionCompleted');
        var headerFixed = false;
        // check if 「#」 exists
        if(location.hash){
            var anchor = document.querySelector( location.hash );
            if(anchor){
                var rect = anchor.getBoundingClientRect();
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                if(headerFixed){
                    var header = document.getElementById('header');
                    if(header){
                        top = top - header.clientHeight;
                    }
                }
                var top = rect.top + scrollTop;
                window.scrollTo(0,top);
            }else{
                // no anchor, go to top position
                window.scrollTo(0,0);
            }
        }else{
            // no anchor, go to top position
            window.scrollTo(0,0);
        }
    }

    cancelSameLinks(){
        // 現在と同じページのリンクをクリックした場合、リロードなし。
        let links = document.querySelectorAll('a[href]');
        let cbk = function(event) {
            if(event.currentTarget.href === window.location.href) {
                event.preventDefault();
                event.stopPropagation();
            }
        };
        for(let i = 0, len = links.length; i < len; i++) {
            links[i].addEventListener('click', cbk);
        }
    }
}

new BarbaCustom();
