import TemplateContents from './TemplateContents';

import {gsap, Quart} from "gsap";
import * as THREE from 'three';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();
        document.documentElement.classList.add('lock');
        gsap.delayedCall(1, ()=>{
            window.scrollTo(0, 0);
        });
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();

        this.enabled = true;
        this.per = 0;
        this.loadCnt = 0;
        this.loadLen = 0;
        this.easePer = 0;
        this.screenAspect = 0;
        this.maskAspect = 31 / 56;
        this.inverseMaskAspect = 1 / this.maskAspect;
    }

    setDom(){
        super.setDom();
        this.loadingDOM = document.querySelector('#loading');
        this.perTxt = document.querySelector('#loading .per_txt');
    }

    initEvents(){
        super.initEvents();
    }

    start(){
        this.pack.textures = {};
        let promiseList = [];
        let imgPathPC = [
            "common/top_background_wall.webp",
            "top/particle.webp",
            "top/background_mountain.webp",
            "top/background_lattice.webp",
            "top/background_footer_pc.webp",
            "top/background_star_pc.webp",
            "top/mv_mask_bg.webp",
            "top/section2_mask_bg.webp",
        ];

        let imgPathSP = [
            "common/top_background_wall.webp",
            "top/particle.webp",
            "top/background_mountain.webp",
            "top/background_lattice_sp.webp",
            "top/background_footer_sp.webp",
            "top/background_star_sp.webp",
            "top/mv_mask_bg_sp.webp",
            "top/section2_mask_bg.webp",
        ];

        let objectName = [
            'bg_wall',
            'bg_particle',
            'bg_mountain',
            'bg_lattice',
            'bg_footer',
            'bg_star',
            'mv_mask_bg',
            'section2_mask_bg',
        ];


        let imgPath = (this.sw >= this.pack.BP) ? imgPathPC : imgPathSP;

        imgPath.forEach ((path, i) => {
            let url = this.pack.IMG_PATH + path;
            promiseList.push(this.loadTexture(url, objectName[i]));
        });

        this.loadLen = promiseList.length;

        // Promise.all(promiseList).then(this.endLoading.bind(this));
        Promise.all(promiseList);
    }

    loadTexture(path, name){
        let loader = new THREE.TextureLoader();
        return new Promise((resolve) => {
            loader.load(
                // resource URL
                path,
                 ( texture ) =>  {
                    texture.minFilter = THREE.LinearFilter;
                    this.pack.textures[name] = texture;
                    this.loadCnt++;
                    this.per = this.loadCnt / this.loadLen;
                     // trace(this.loadCnt, this.per);
                     // trace(name);
                     if(name === 'bg_wall') this.dispatchEvent('loadedBGWall');
                    return resolve();
                },

                // onProgress callback currently not supported
                undefined,

                // onError callback
                function ( err ) {
                    console.error( 'An error happened. at loadTexture, WireframePolygon' );
                }
            );
        });
    }


    scaleOut(){
        //ローディングが捌けるモーションが始まる前にTOPの初期化をしてしまう
        if(this.pack.current === 'top') this.pack.top.set();

        this.enabled = false;
        let obj = {per:5};
        let dr = .9;
        gsap.to(obj, {duration:dr, per:50, ease:Quart.easeInOut, onUpdate:()=>{
            this.updateMask(obj.per);
        }});

        //少しだけ先行スタートさせる
        gsap.delayedCall(dr - .1, ()=>{
            this.endLoading();
        });
    }

    endLoading(){
        this.dispatchEvent('loadCompleted');
        gsap.delayedCall(.1, ()=>{
            this.loadingDOM.classList.add('hide');
        });
    }

    scrollHandler(){

    }

    updateMask(per){
        let perX, perY;
        if(this.screenAspect >= this.maskAspect){
            perX = per;
            perY = per * this.inverseMaskAspect * this.screenAspect;
        }else{
            perX = per * this.maskAspect * this.inverseScreenAspect;
            perY = per;
        }

        let x = 50 - perX;
        let y = 50 - perY;
        let w = 50 + perX;
        let h = 50 + perY;

        this.loadingDOM.style.clipPath = `
            polygon(
            0% 0%, 100% 0%, 100% 100%, 0% 100%,0% 0%,
            ${x}% ${y}%, ${x}% ${h}%, ${w}% ${h}%, ${w}% ${y}%, ${x}% ${y}%
            )
        `;
    }

    enterframe(){

    }

    enterframeThinOut(){
        if(!this.enabled) return;

        this.easePer += (this.per - this.easePer) * .1;
        let per = Math.ceil(this.easePer * 100);

        this.perTxt.innerText = per + '%';

        this.updateMask(per / 20);

        if(per >= 100) this.scaleOut();
    }

    executeResize() {
        super.executeResize();

        this.screenAspect = this.sw / this.sh;
        this.inverseScreenAspect = 1 / this.screenAspect;
        // trace(this.screenAspect, this.maskAspect, this.inverseMaskAspect);
    }
}