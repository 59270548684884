import TemplateContents from './TemplateContents';
import {gsap} from "gsap";

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        if(this.selectDom) this.selectDom.removeEventListener('change', this.bindChangeSelectHandler);

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    destructAfterContentsOut(){

    }

    setVars(){
        super.setVars();
        const categories = document.querySelectorAll('.categories ul li');
        categories.forEach((category)=>{
            if (category.classList.contains('current')) {
                sessionStorage.setItem('referrer', category.dataset.slug)
            }
        });
    }

    setDom(){
        super.setDom();

        this.section1Dom = document.querySelector('main.news .section1');
        this.selectDom = document.querySelector('main.news .section1 .ui .pulldown select');
        this.section1H2Pathes = document.querySelectorAll('main.news .section1 h2 svg path');
    }

    initEvents(){
        super.initEvents();

        this.bindChangeSelectHandler = this.changeSelectHandler.bind(this);
        if(this.selectDom) this.selectDom.addEventListener('change', this.bindChangeSelectHandler);
    }

    changeSelectHandler(event){
        // Barba.Pjax.goTo('/news/' + this.selectDom.value + '/');
        Barba.Pjax.goTo(this.selectDom.value);
    }

    setTransitionDelay(){
        let singleDelay = .08;
        let delayAll = singleDelay * (this.section1H2Pathes.length - 1);
        for( let i = 0, len = this.section1H2Pathes.length; i < len; i++ ){
            let item = this.section1H2Pathes[i];
            let delay = delayAll - (singleDelay * i);
            item.style.transitionDelay = delay + 's';
        }
    }

    start(isLanding){
        let delay;
        if(isLanding){
            delay = .01;
        }else{
            delay = .5;
        }

        this.setTransitionDelay();

        gsap.delayedCall(delay, ()=>{
            this.section1Dom.classList.add('show');
        });
    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
    }
}