import {ua} from './libs/daijima/ua';
import {gsap, Quart} from "gsap";

export default class Config{
    static instance;

    constructor(caller) {
        // if (caller != Config.getInstance) {
        if (caller != 'getInstance') {      //TODO
            throw new Error("ConfigクラスはSingletonです。getInstance()メソッドを使ってインスタンス化してください。");
        }

        if(Config.instance != null){
            throw new Error("ConfigクラスはSingletonです。インスタンスはひとつしか生成できません。");
        }else{
            Config.instance = this;
        }

        this.init();
    }

    static getInstance(){
        // if(Config.instance == null) new Config(arguments.callee);
        if(Config.instance == null) new Config('getInstance');  //TODO
        return Config.instance;
    }

    init(){
        this.BP = 768;
        this.ua = ua;
        this.hasTouch = ("ontouchstart" in window);
        this.current = '';

        this.scrollY = 0;
        this.pastScrollY = 0;
        this.scrollWrapper = document.querySelector('.scroll-wrapper');
        this.BASE_PATH = '/assets/';
        this.IMG_PATH =
            EXTENTION === 'html'
                ? this.BASE_PATH + 'img/'
                : '/wp-content/themes/rerize' + this.BASE_PATH + 'img/';

        this.getFormatDate = (date) => {
            let dateArray = String(new Date(date)).split(" ");
            return dateArray[1] + " " + dateArray[2] + ", " + dateArray[3];
        };

        // 3桁カンマ区切りとする
        this.addComma = (num) => {
            var s = String(num).split('.');
            var ret = String(s[0]).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            if (s.length > 1) {
                ret += '.' + s[1];
            }
            return ret;
        };

        this.d2r = function(d){
            return d * Math.PI / 180;
        };

        this.r2d = function(r){
            return r * 180 / Math.PI;
        };

        this.random = function(min, max){
            return Math.random() * (max - min) + min;
        };

        /**
         * 線形補間（lerp）関数
         * @param {number} a - 開始値
         * @param {number} b - 終了値
         * @param {number} t - 補間パラメータ（0から1の範囲）
         * @returns {number} 補間された値
         */
        this.lerp = (a, b, t) => {
            // tが0から1の範囲外の場合、範囲内に収める
            t = Math.max(0, Math.min(1, t));
            return a + (b - a) * t;
        };

        this.lockHTML = ()=>{
            let html = document.documentElement;
            html.classList.add("hidden");
        };

        this.unlockHTML = ()=>{
            let html = document.documentElement;
            html.classList.remove("hidden");
        };

        this.anchorScroll = function(targetY, dr, ease, cb){
            if(!dr) dr = 1;
            if(!ease) ease = Quart.easeInOut;
            let obj = {y:window.scrollY};
            gsap.killTweensOf(obj);
            gsap.to(obj, dr, {y:targetY, ease:ease, onUpdate:function(){
                    window.scrollTo(0, obj.y);
                }, onComplete:function(){
                    if(cb) cb();
                }});
        };

        this.addZero = function(str){
            if(str.length == 1) str = "0"+str;
            return str;
        };

        this.getPCVW = (v)=>{
            return v / 1440 * 100;
        };

        this.getSPVW = (v)=>{
            return v / 390 * 100;
        };
    }
}