import TemplateContents from './TemplateContents';

import {gsap, Quart} from "gsap";
import GUI from 'lil-gui';
import * as THREE from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import vertex from './shaders/vertex_mask4.glsl';
import fragment from './shaders/frag_mask4.glsl';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init(true);

        this.canvasContainer = document.querySelector('main.top .section4 .mask_target');
        this.canvas = document.createElement("canvas");
        this.canvasContainer.appendChild(this.canvas);
        this.sw = this.canvasContainer.offsetWidth;
        this.sh = this.canvasContainer.offsetHeight;
        this.canvas.width = this.sw;
        this.canvas.height = this.sh;


        // this.initGUI();
        this.initWebGL();
        this.initMesh();
        this.resetVideo();
        this.initEvents();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        // super.reset();

        this.setDom();
        this.setVars();

        this.canvasContainer = document.querySelector('main.top .section4 .mask_target');
        this.canvasContainer.appendChild(this.canvas);
        this.sw = this.canvasContainer.offsetWidth;
        this.sh = this.canvasContainer.offsetHeight;
        this.canvas.width = this.sw;
        this.canvas.height = this.sh;
        this.resetVideo();
        this.initEvents();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.videoDOM.removeEventListener('ended', this.bindEndedVideoHandler);

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    destructAfterContentsOut(){
        this.videoTexture.dispose();
        this.material.uniforms.uVideoTexture.value = null;
        this.videoDOM.src = '';
        this.render();

        this.canvas.parentNode.removeChild(this.canvas);
    }

    setVars(){
        super.setVars();

        this.startedAnimation = false;
        // this.endedVideo = false;
    }

    setDom(){
        super.setDom();
        this.videoPCDOM = document.querySelector('main.top .section4 video.pc');
        this.videoSPDOM = document.querySelector('main.top .section4 video.sp');
    }

    initEvents(){
        super.initEvents();

        // this.bindEndedVideoHandler = this.endedVideoHandler.bind(this);
        // this.videoDOM.addEventListener('ended', this.bindEndedVideoHandler);
    }

/*
    endedVideoHandler(event){
        // this.endedVideo = true;
    }
*/

    start(){
        // gsap.delayedCall(.6, ()=>{
            this.videoDOM.play();
            this.startedAnimation = true;
        // });
    }

    initGUI(){
        this.settings = {
            scale: 1,
            adjustX: 0,
            adjustY: 0,
        };
        const gui = this.gui = new GUI();
        gui.add(this.settings, 'scale', 0.001, 2.0, 0.01);
        gui.add(this.settings, 'adjustX', -.5, .5, 0.01);
        gui.add(this.settings, 'adjustY', -.5, .5, 0.01);
    }

    initWebGL() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            alpha : true,
            antialias : true
        });
        // this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setPixelRatio(1);
        this.renderer.setSize(this.sw, this.sh);
        this.renderer.autoClear = false;

        this.scene = new THREE.Scene();

        let frustumSize = 1;

        this.camera = new THREE.OrthographicCamera(frustumSize / -2, frustumSize / 2, frustumSize / 2, frustumSize / -2, -1000, 1000);
        this.camera.position.set(0,0,2);

        // this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    }

    initMesh(){
        let textureAspect, scale, adjustX, adjustY;

        if(this.sw >= this.pack.BP) {
            scale = 1.06;
            adjustX = -.002;
            adjustY = 0.01;
            textureAspect = 1800 / 1100;
        }else {
            scale = 1.13;
            adjustX = 0;
            adjustY = -.12;
            textureAspect = 700 / 800;
        }


        let geometry = new THREE.PlaneGeometry( 1, 1, 32 );
        let material = this.material = new THREE.ShaderMaterial({
            side: THREE.DoubleSide,
            uniforms:{
                uViewport: {value: new THREE.Vector2(this.sw, this.sh)},
                uVideoTexture: {value: null},
                uTextureAspect: {value: textureAspect},
                uScale: {value: scale},
                uAdjust: {value: new THREE.Vector2(adjustX, adjustY)},
            },
            vertexShader: vertex,
            fragmentShader: fragment,
            transparent: true
        });

        let plane = new THREE.Mesh( geometry, material );
        this.scene.add( plane );
    }

    resetVideo(){
        if(this.sw >= this.pack.BP) this.videoDOM = this.videoPCDOM;
        else this.videoDOM = this.videoSPDOM;

        this.videoTexture = new THREE.VideoTexture(this.videoDOM);
        this.material.uniforms.uVideoTexture.value = this.videoTexture;
        this.render();
    }


    scrollHandler(){

    }

    render(){
        // this.material.uniforms.uScale.value = this.settings.scale;
        // this.material.uniforms.uAdjust.value = new THREE.Vector2(this.settings.adjustX, this.settings.adjustY);
        this.renderer.render(this.scene, this.camera);
    }

    enterframe(){

    }

    enterframeThinOut(){
        // if(!this.endedVideo)this.render();
        this.render();
    }

    executeResize() {
        // super.executeResize();
        this.sw = this.canvasContainer.offsetWidth;
        this.sh = this.canvasContainer.offsetHeight;
        this.swh = this.sw / 2;
        this.shh = this.sh / 2;

        if(!this.canvas) return;

        this.canvas.width = this.sw;
        this.canvas.height = this.sh;

        if(!this.camera) return;

        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.sw, this.sh);

        this.material.uniforms.uViewport.value = new THREE.Vector2(this.sw, this.sh);
        this.render();

    }
}